import React from "react";
import "./models-section.scss";
import TopLink from "../../../../components/top-link/top-link";
import { RoutePath } from "../../../../route-paths";
import ImgtextBlock from "../../../../components/img-text-block/img-text-block";
import { generatePath } from "react-router-dom";

interface Props {
  className?: string;
  previewImg: string;
  modelId: string;
  name: string;
}

const ModelItem: React.FC<Props> = (props: Props) => {
  const { className, previewImg, name, modelId } = props;

  return (
    <TopLink to={generatePath(RoutePath.MODEL, { modelId: modelId })} className="model-item">
      <ImgtextBlock img={previewImg} alt={name} aosDelay={0} className={"pt-md-5 mt-md-5 pt-3 mt-3 pb-md-3 pb-5" + className} key={modelId}>
        <p className="subtitle pt-2">
          <b>{name} &gt;</b>
        </p>
      </ImgtextBlock>
    </TopLink>
  );
};

export default ModelItem;
