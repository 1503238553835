import { Store } from "redux";
import { globalStore } from "../index";

var initialState = {
  bgColor: { hex: "#ffffff", speed: 2000 }
}

class Actions {
  static setBgColor = (hex: string, speed?: number) => {
    globalStore.DispatchAction(
      ReduxBg.prefix,
      {
        type: ReduxBg.prefix + "setBgColor",
        payload: { hex: hex, speed: speed ?? 2000 },
      }
    )
  }
}

class Reducer {
  static Register = (state = initialState, action: any) => {
    if (action.type === ReduxBg.prefix + "setBgColor")
      return { ...state, bgColor: action.payload };
    return state;
  }
}

class Init {
  static Register = () => {
    ReduxBg.localStore = globalStore.CreateStore(ReduxBg.prefix, Reducer.Register, []);
    globalStore.RegisterGlobalActions(ReduxBg.prefix, [
      ReduxBg.prefix + "setBgColor",
    ]);
  }
}

class ReduxBg {
  static prefix = "ReduxBg_";
  static localStore: Store<any, any>;
  Init = Init;
  static Actions = Actions;
}

export default ReduxBg;