import React from "react";
import Container from "../../../../components/container/container";
import Col from "../../../../components/grid/col";
import Grid from "../../../../components/grid/grid";
import Row from "../../../../components/grid/row";
import TextBlock from "../../../../components/writing-tools/text-block/text-block";
import ColorSection from "../../../../components/color-section/color-section";
import TopLink from "../../../../components/top-link/top-link";
import { RoutePath } from "../../../../route-paths";
import models from "../../../../data/models/models";
import useWindowSize from "../../../../tools/useWindowSize";
import ModelItem from "./model-item";
import "./models-section.scss";

interface Props {
  className?: string;
}

const ModelsSection: React.FC<Props> = (props: Props) => {
  const { className } = props;
  const size = useWindowSize();

  return (
    <ColorSection className={`models-section mb-5 ${className ? className : ""}`} dataAos="fade-up" color="white">
      <Container>
        <Grid>
          <Row gap={96}>
            <Col size={[6, 6, 12, 12]}>
              <TextBlock className="pb-md-5 mb-md-5 pb-3 mb-3">
                <div>
                  <TopLink to={RoutePath.HOME} className="more-link mb-4">
                    &lt; Powrót na stronę główną
                  </TopLink>
                </div>
                <h1 className="title" data-aos="fade-up">
                  Modele 3D
                </h1>
              </TextBlock>
              {size.width > 1200
                ? models
                    .filter((x, index) => index % 2 === 0)
                    .map((x, index) => {
                      return <ModelItem modelId={x.url} name={x.name} previewImg={x.previewImg} key={index} />;
                    })
                : null}
            </Col>
            {size.width > 1200 ? (
              <Col size={[6, 6, 12, 12]} className="pt-4">
                {models
                  .filter((x, index) => index % 2 === 1)
                  .map((x, index) => {
                    return <ModelItem modelId={x.url} name={x.name} previewImg={x.previewImg} key={index} />;
                  })}
              </Col>
            ) : null}
            {size.width <= 1200 ? (
              <Col size={[6, 6, 12, 12]} className="pt-4">
                {models.map((x, index) => {
                  return <ModelItem modelId={x.url} name={x.name} previewImg={x.previewImg} key={index} />;
                })}
              </Col>
            ) : null}
          </Row>
        </Grid>
      </Container>
    </ColorSection>
  );
};

export default ModelsSection;
