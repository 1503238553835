import React, { useEffect, useState } from "react";
import { generatePath, useParams } from "react-router-dom";
import TopLink from "../../../../components/top-link/top-link";
import news, { NewsDto } from "../../../../data/news/news";
import { RoutePath } from "../../../../route-paths";
import "./news-nav.scss";

const NewsNav: React.FC = () => {
  const { newsId }: { newsId: string } = useParams();
  const [newsItemNext, setNewsItemNext] = useState<NewsDto | undefined>(undefined);
  const [newsItemPrev, setNewsItemPrev] = useState<NewsDto | undefined>(undefined);

  useEffect(() => {
    const newsDataIndex = news.findIndex((x) => x.url === newsId);
    if (newsDataIndex !== -1) {
      if (news.length > newsDataIndex) {
        setNewsItemNext(news[newsDataIndex + 1]);
      } else setNewsItemNext(undefined);
      if (newsDataIndex > 0) {
        setNewsItemPrev(news[newsDataIndex - 1]);
      } else setNewsItemPrev(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsId]);

  return (
    <div className="news-nav">
      <div className={`news-button-prev ${newsItemPrev ? "" : "not-clickable"}`}>{newsId && newsItemPrev ? <TopLink to={generatePath(RoutePath.NEWS, { newsId: newsItemPrev?.url ?? "" })}>&lt;</TopLink> : <>&lt;</>}</div>
      <div className={`news-button-next ${newsItemNext ? "" : "not-clickable"}`}>{newsId && newsItemNext ? <TopLink to={generatePath(RoutePath.NEWS, { newsId: newsItemNext?.url ?? "" })}>&gt;</TopLink> : <>&gt;</>}</div>
    </div>
  );
};

export default NewsNav;
