import React from "react";
import Footer from "../../sections/footer/footer";
import PrefooterSection from "../../sections/prefooter-section/prefooter-section";
import "./aplikacja-ar-page.scss";
import GetAppSection from "./sections/GetAppSection/GetAppSection";
import AplikacjaARSection from "./sections/multimedia-section/aplikacja-ar-section";

const AplikacjaARPage: React.FC = () => {
  return (
    <div className="aplikacja-ar-page">
      <AplikacjaARSection />
      <GetAppSection />
      <br />
      <PrefooterSection />
      <Footer />
    </div>
  );
};

export default AplikacjaARPage;
