import React from "react";
import Footer from "../../sections/footer/footer";
import PrefooterSection from "../../sections/prefooter-section/prefooter-section";
import "./about-page.scss";
import AboutSection from "./sections/about-section/about-section";
import PartnersSection from "./sections/partners-section/partners-section";
import PatroniSection from "./sections/patroni/patroni-section";
import TeamPawilonSection from "./sections/team-pawilon-section/team-pawilon-section";
import TeamSection from "./sections/team-section/team-section";

const AboutPage: React.FC = () => {
  return (
    <div className="about-page">
      <AboutSection />
      <br />
      <PartnersSection />
      <br />
      <PatroniSection />
      <br />
      <TeamSection />
      <br />
      <TeamPawilonSection />
      <br />
      <PrefooterSection />
      <Footer />
    </div>
  );
};

export default AboutPage;
