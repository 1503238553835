import React from "react";
import "./articles-swiper.scss";
import DefaultbgImg from "./../../../../../assets/defaultArticleBg.svg";
import TopLink from "../../../../../components/top-link/top-link";

interface Props {
  title: React.ReactNode;
  bgImg?: string;
  children: React.ReactNode;
  offset?: number;
  to?: string;
  alt?: string;
}

const Article: React.FC<Props> = (props: Props) => {
  const { bgImg = DefaultbgImg, title, offset, to, alt } = props;

  return (
    <div className="article-block-wrapper" data-aos={offset && offset < 100 ? "slide-up" : "slide-down"} data-aos-delay={offset?.toString()}>
      <div className="article-block">
        <div className="article-block__img" role="img" style={{ backgroundImage: `url('${bgImg}')` }} title={alt}></div>
        <div className="article-block__content" role="img" title={alt}>
          <h3>{title}</h3>
          {/* <p>{children}</p> */}
          {to ? (
            <TopLink to={to} className="more-link" title={"Więcej: " + alt} label={"Więcej: " + alt}>
              Więcej &gt;
            </TopLink>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Article;
