import { Store } from "redux";
import { globalStore } from "../index";

var initialState = {
  isPopupHidden: localStorage.getItem(window.location.hostname + ':isPopupHidden') ? true : false
}

class Actions {
  static hidePolicyPopup = () => {
    localStorage.setItem(window.location.hostname + ':isPopupHidden', 'true');
    globalStore.DispatchAction(
      ReduxCookie.prefix,
      {
        type: ReduxCookie.prefix + "PolicyPopup",
        payload: true
      }
    )
  }
}

class Reducer {
  static Register = (state = initialState, action: any) => {
    if (action.type === ReduxCookie.prefix + "PolicyPopup")
      return { ...state, isPopupHidden: action.payload };
    return state;
  }
}

class Init {
  static Register = () => {
    ReduxCookie.localStore = globalStore.CreateStore(ReduxCookie.prefix, Reducer.Register, []);

    globalStore.RegisterGlobalActions(ReduxCookie.prefix, [
      ReduxCookie.prefix + "PolicyPopup",
    ]);
  }
}

class ReduxCookie {
  static prefix = "ReduxCookie_";
  static localStore: Store<any, any>;
  Init = Init;
  static Actions = Actions;
}

export default ReduxCookie;