import React from "react";
import Col from "../../../components/grid/col";
import Row from "../../../components/grid/row";
import TextBlock from "../../../components/writing-tools/text-block/text-block";
import Prefab from "../../../pages/model-page/sections/model-section/prefab";
import ModelWrapper from "../model-wrapper";
import KlatkaAudio from "./klatka.ogg";

interface Props {
  className?: string;
}

const ModelKlatka: React.FC<Props> = (props: Props) => {
  const { className } = props;

  return (
    <ModelWrapper className={className}>
      <Row>
        <Col size={[12, 12, 12, 12]}>
          <Prefab src="https://sketchfab.com/models/6922c626434d4694865b15a81c18cb83/embed" />
          <TextBlock className="pt-3">
            <h1 className="subtitle pb-4 thin">
              <b>Klatka,</b>&nbsp;1985
            </h1>
          </TextBlock>
        </Col>
      </Row>
      <Row>
        <Col size={[6, 6, 12, 12]} className="pt-3">
          <Row>
            <Col size={[8, 8, 12, 12]}>
              <TextBlock>
                <p>Rzeźba Magdaleny Abakanowicz zatytułowana „Klatka”, 1985.</p>
                <h2 className="pt-3">
                  <b>Wymiary figury:</b>
                </h2>
                <p>
                  wysokość - 188 cm <br />
                  szerokość - 153 cm <br />
                  głębokość - 124 cm
                </p>
              </TextBlock>
            </Col>
            <Col size={[4, 4, 12, 12]}>
              <TextBlock>
                <h2 className="pt-3">
                  <b>Materiał:</b>
                </h2>
                <p>drewno, tkanina jutowa.</p>
              </TextBlock>
            </Col>
          </Row>
          <Row>
            <Col size={[12, 12, 12, 12]} className="pt-3">
              <TextBlock>
                <p>Dzieło ze zbiorów Muzeum Narodowego we Wrocławiu prezentowane na wystawie stałej w Pawilonie Czterech Kopuł.</p>
              </TextBlock>
            </Col>
          </Row>
        </Col>
        <Col size={[6, 6, 12, 12]} className="pt-3">
          <TextBlock>
            <p className="title-audio">
              <b>Posłuchaj opisu:</b>
              <audio autoPlay={false} controls controlsList="nofullscreen nodownload noremoteplayback noplaybackrate">
                <source src={KlatkaAudio} type="audio/ogg" />
                Your browser does not support the audio tag.
              </audio>
            </p>
            <p>
              W drewnianej konstrukcji przypominającej klatkę Abakanowicz zamyka wydrążoną ludzką skorupę, a właściwie jej fragment. Czy to pancerz, czy też ślad człowieka? Skrywa ona, odsłania? Rzeźba „Klatka” Magdaleny Abakanowicz to punkt wyjścia do dyskusji o kondycji człowieka. Istoty wyniesionej i upodlonej, istoty w tłumie i istoty cechującej się indywidualnością jednocześnie. Praca ta mocno przylega do kontekstu polskiej historii lat 80., realiów stanu wojennego i zagadnienia wolności osobistych oraz nakładanych systemowo ograniczeń. Pozwala też jednak na rozszerzenie kontekstu rozważań do pojęcia człowieka w ogóle - jego związku z naturą, jego możliwości, granic i instynktów - pozwalając na wielowątkową dyskusję z włączeniem kontekstów literackich.
              <br /> <br />
              Dzieło przedstawia drewnianą klatkę oraz umieszczony w niej korpus człowieka. Klatka ma kształt sześcianu. Zrobiona jest z czterech pionowych belek o wysokości 188 cm połączonych ośmioma poziomymi belkami. Cztery z nich znajdują się w górnej części pionowych belek (15 centymetrów od góry). Kolejne cztery w dolnej części (15 cm od dołu). Górna i dolna konstrukcja połączone są za pomocą ukośnych belek, łączących górny i dolny róg bocznych ścian sześcianu. W środku, na wysokości dolnego wzmocnienia klatki, na całej szerokości przymocowana jest drewniana deseczka, na której umieszczona jest postać. Forma przedstawia odcisk ludzkich pleców, od szyi aż po pośladki. Postać pozbawiona jest rąk, nóg oraz głowy. Przedstawia pustą skorupę w kształcie zgarbionej ludzkiej sylwetki. Figura ta, wysokości 124 centymetrów, wykonana została z tkaniny jutowej.
              <br /> <br />
              „Klatka” Magdaleny Abakanowicz jest nawiązaniem do sytuacji politycznej w Polsce w okresie stanu wojennego. Anonimowa, skulona postać, uwięziona w zbitej z surowych belek klatce może być interpretowana jako symbol zniewolenia.
            </p>
          </TextBlock>
        </Col>
      </Row>
    </ModelWrapper>
  );
};

export default ModelKlatka;
