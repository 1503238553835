import React from "react";
import Col from "../../../components/grid/col";
import Row from "../../../components/grid/row";
import TextBlock from "../../../components/writing-tools/text-block/text-block";
import Prefab from "../../../pages/model-page/sections/model-section/prefab";
import ModelWrapper from "../model-wrapper";
import UglowionyAudio from "./uglowiony.ogg";

interface Props {
  className?: string;
}

const ModelUglowiony: React.FC<Props> = (props: Props) => {
  const { className } = props;

  return (
    <ModelWrapper className={className}>
      <Row>
        <Col size={[12, 12, 12, 12]}>
          <Prefab src="https://sketchfab.com/models/ad573175cb244211991777130f39192a/embed" />
          <TextBlock className="pt-3">
            <h1 className="subtitle pb-4 thin">
              <b>Ugłowiony,</b>&nbsp;1997-1998
            </h1>
          </TextBlock>
        </Col>
      </Row>
      <Row>
        <Col size={[6, 6, 12, 12]} className="pt-3">
          <Row>
            <Col size={[8, 8, 12, 12]}>
              <TextBlock>
                <p>Rzeźba „Ugłowiony” tworzona przez Magdalenę Abakanowicz, w latach 1997-98.</p>
                <h2 className="pt-3">
                  <b>Wymiary figury:</b>
                </h2>
                <p>
                  wysokość - 180 cm <br />
                  szerokość - 55 cm <br />
                  głębokość - 43 cm
                </p>
              </TextBlock>
            </Col>
            <Col size={[4, 4, 12, 12]}>
              <TextBlock>
                <h2 className="pt-3">
                  <b>Materiał:</b>
                </h2>
                <p>tkanina jutowa, żywica.</p>
              </TextBlock>
            </Col>
          </Row>
          <Row>
            <Col size={[12, 12, 12, 12]} className="pt-3">
              <TextBlock>
                <p>Dzieło ze zbiorów Muzeum Narodowego we Wrocławiu prezentowane na wystawie stałej w Pawilonie Czterech Kopuł.</p>
              </TextBlock>
            </Col>
          </Row>
        </Col>
        <Col size={[6, 6, 12, 12]} className="pt-3">
          <TextBlock>
            <p className="title-audio">
              <b>Posłuchaj opisu:</b>
              <audio autoPlay={false} controls controlsList="nofullscreen nodownload noremoteplayback noplaybackrate">
                <source src={UglowionyAudio} type="audio/ogg" />
                Your browser does not support the audio tag.
              </audio>
            </p>
            <p>
              Rzeźba „Ugłowiony” Magdaleny Abakanowicz przedstawia stojącą postać wysokości
              180 cm, szeroką na 55 cm, głęboką na 43 cm. Rzeźba wykonana została z tkaniny
              jutowej utwardzonej żywicą. Ukazana figura ludzka pozbawiona jest rąk. Przedstawia
              postać w lekko zgarbionej pozie, na ugiętych nogach. Stopy postaci są lekko
              rozstawione. Głowa jest zaprezentowana jedynie symbolicznie, bez wyraźnie
              zarysowanych elementów twarzy. Mamy jedynie delikatne wgłębienia w miejsce oczu
              oraz drobną wypukłość w miejscu nosa. Wokół linii ramion pojawia się odstający
              od ciała fragment przypominający kształtem powiewającą tkaninę. Zupełnie, jakby
              na ramiona „Ugłowionego” został narzucony zwiewny materiał. „Ugłowiony” składa się
              wyłącznie ze skorupy przedstawiającej odcisk przodu ludzkiej sylwetki. Cały tył jest
              pusty i wydrążony.
              <br /><br />
              Praca ta stanowi w działaniach edukacyjnych Pawilonu Czterech Kopuł bardzo ważny
              punkt odniesienia do pytania o rolę myśli posthumanistycznej we współczesnej sztuce.
              Prowadząc lekcje muzealne, można stworzyć bogatą narrację wokół tej pracy
              Magdaleny Abakanowicz oraz postawić szereg pytań dotyczących zmian, jakie zaszły
              na polu sztuki od lat 90. XX wieku, do dnia dzisiejszego.
            </p>
          </TextBlock>
        </Col>
      </Row>
    </ModelWrapper>
  );
};

export default ModelUglowiony;
