import React from "react";
import ColorSection from "../../../components/color-section/color-section";
import Container from "../../../components/container/container";
import Col from "../../../components/grid/col";
import Grid from "../../../components/grid/grid";
import Row from "../../../components/grid/row";
import TopLink from "../../../components/top-link/top-link";
import TextBlock from "../../../components/writing-tools/text-block/text-block";
import Prefab from "../../../pages/model-page/sections/model-section/prefab";
import { RoutePath } from "../../../route-paths";
import "./../models.scss";

import AbakanAudio from "./abakan.ogg";

interface Props {
  className?: string;
}

const ModelAbakan: React.FC<Props> = (props: Props) => {
  const { className } = props;

  return (
    <ColorSection className={`model-section ${className ? className : ""}`} dataAos="fade-up" color="white">
      <Container>
        <Grid>
          <Row>
            <Col size={[12, 12, 12, 12]}>
              <TextBlock className="pb-5">
                <div>
                  <TopLink to={RoutePath.MODELS} className="more-link">
                    &lt; Powrót do modeli 3D
                  </TopLink>
                </div>
              </TextBlock>
              <Prefab src="https://sketchfab.com/models/9eb23ff1fc0b4745aafbeb7d47d51d35/embed" />
            </Col>
          </Row>
          <Row>
            <Col size={[12, 12, 12, 12]} className="pt-3">
              <TextBlock>
                <h1 className="subtitle pb-4 thin">
                  <b>Abakan&nbsp;żółty,</b>&nbsp;1970-1975
                </h1>
              </TextBlock>
            </Col>
          </Row>
          <Row>
            <Col size={[6, 6, 12, 12]} className="pt-3">
              <Row>
                <Col size={[8, 8, 12, 12]}>
                  <TextBlock>
                    <p>Praca Magdaleny Abakanowicz zatytułowana „Abakan żółty”, tworzona w latach 1970-75.</p>
                    <h2 className="pt-3">
                      <b>Wymiary:</b>
                    </h2>
                    <p>
                      wysokość - 300 cm <br />
                      szerokość - 300 cm <br />
                      głębokość - 50 cm
                    </p>
                  </TextBlock>
                </Col>
                <Col size={[4, 4, 12, 12]}>
                  <TextBlock>
                    <h2 className="pt-3">
                      <b>Materiał:</b>
                    </h2>
                    <p>sizal tkany, barwiony.</p>
                  </TextBlock>
                </Col>
              </Row>
              <Row>
                <Col size={[12, 12, 12, 12]} className="pt-3">
                  <TextBlock>
                    <p>Dzieło ze zbiorów Muzeum Narodowego we Wrocławiu.</p>
                  </TextBlock>
                </Col>
              </Row>
            </Col>
            <Col size={[6, 6, 12, 12]} className="pt-3">
              <TextBlock>
                <p className="title-audio">
                  <b>Posłuchaj opisu:</b>
                  <audio autoPlay={false} controls controlsList="nofullscreen nodownload noremoteplayback noplaybackrate">
                    <source src={AbakanAudio} type="audio/ogg" />
                    Your browser does not support the audio tag.
                  </audio>
                </p>
                <p>
                  Abakan to wyjątkowa forma rzeźbiarska, połączenie tkaniny i rzeźby. Abakany nie są wykonane z twardych materiałów jak klasyczna rzeźba. Nie są również płaskie jak dzieło wykonane z tkaniny. Abakan, potocznie określany jako miękka rzeźba, wykonany jest z grubo tkanych sizalowych splotów. Abakanowicz, przy tworzeniu, dzieliła włókna na cieńsze pasma, następnie barwiła je i suszyła. Potem, tkała z nich na krośnie swoje abakany. Cały ten proces sprawiał, że w trakcie tworzenia przypominały one kształtem gobeliny, czyli płaskie tkaniny. Abakan żółty, wykonany jest z gęstych połączeń pojedynczych włókien. Misternie przenikające się pasma, nadają tkaninie twardy, szorstki charakter. Sposób prowadzenia włókna jest poziomy, równoległy. Wszelkie łączenia materiału są niewidoczne. Po zszyciu wszystkich elementów, Abakanowicz nadawała im przestrzenną formę. <br />
                  <br />
                  Abakan żółty ma kształt łezki. Węższy na górze, symetryczne rozszerza się ku dołowi. Jego kształt jest opływowy, nie ma w nim kanciastych elementów.
                  <br /> <br />"<i>Moje trójwymiarowe tkane formy są sprzeciwem wobec systematyzacji życia i sztuki. Rosną powolnym rytmem, jak twory natury[…] Jak twory natury są organiczne. Jak inne twory natury są obiektami do kontemplacji.</i>" <br />- Magdalena Abakanowicz
                  <br />
                  <br />
                  Dzieło Abakanowicz zawłaszcza przestrzeń, sprawia wrażenie, jakby otaczało widza z każdej strony.
                </p>
              </TextBlock>
            </Col>
          </Row>
          <Row>
            <Col size={[12, 12, 12, 12]}>
              <TextBlock className="pb-5 mt-4">
                <div>
                  <TopLink to={RoutePath.MODELS} className="more-link">
                    &lt; Powrót do modeli 3D
                  </TopLink>
                </div>
              </TextBlock>
            </Col>
          </Row>
        </Grid>
      </Container>
    </ColorSection>
  );
};

export default ModelAbakan;
