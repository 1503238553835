import "./home-page.scss";
import React from "react";
import PrefooterSection from "../../sections/prefooter-section/prefooter-section";
import Footer from "../../sections/footer/footer";
import HeroSection from "./sections/hero-section/hero-section";
import SlideTextImg from "../../assets/abakanowicz-big.svg";
import CheckModelsSection from "./sections/check-models-section/check-models-section";
import EduPathsSection from "./sections/edu-paths-section/edu-paths-section";
import HeroImg from "../../assets/hero-background.png";
import HeroImgDark from "../../assets/hero-background-dark.png";
import ArSection from "./sections/ar-section/ar-section";
import NewsSection from "./sections/news-section/news-section";
import ArticlesSection from "./sections/articles-section/articles-section";
import MultimediaSection from "./sections/multimedia-section/multimedia-section";

const HomePage: React.FC = () => {
  return (
    <div className="home-page">
      <HeroSection img={SlideTextImg} alt="Abakanowicz logo" bgImg={HeroImg} bgImgDark={HeroImgDark}></HeroSection>
      <div className="overflow-wrapper">
        <br />
        <ArticlesSection />
        <br />
        <EduPathsSection />
        <br />
        <NewsSection />
        <br />
        <CheckModelsSection />
        <br />
        <MultimediaSection />
        <br />
        <ArSection />
        <br />
        <PrefooterSection />
        <Footer />
      </div>
    </div>
  );
};

export default HomePage;
