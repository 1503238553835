import React, { useEffect, useRef } from "react";
import useWindowSize from "../../tools/useWindowSize";
import "./hover-parallax.scss";

interface Props {
  children: React.ReactNode;
  fitContent?: boolean;
}

const HoverParallax: React.FC<Props> = (props: Props) => {
  const { children, fitContent = false } = props;
  const ref = useRef<any>();
  const windowsSize = useWindowSize();
  const handleMousePosition = () => {
    if (windowsSize.width < 900 && ref.current) {
      ref.current.style.transform = `perspective(0px) rotateX(${0}deg) rotateY(${0}deg)`;
    } else if ((window as any).mouse && ref.current) {
      const position = (window as any).mouse.clientRelative;
      ref.current.style.transform = `perspective(1000px) rotateX(${-position.y * 20}deg) rotateY(${position.x * 30}deg)`;
    }
    requestAnimationFrame(handleMousePosition);
  };

  useEffect(() => {
    requestAnimationFrame(handleMousePosition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div ref={ref} className={`hover-parallax ${fitContent ? "fit-content" : ""}`}>
      <div>{children}</div>
    </div>
  );
};

export default HoverParallax;
