import React from "react";
import "./title-block.scss";

interface Props {
  children: React.ReactNode;
  align?: "left" | "center" | "right";
  smallPaddingTop?: boolean;
}

const TitleBlock: React.FC<Props> = (props: Props) => {
  const { children, align = "left" } = props;
  return (
    <div className={`title-block title-block--${align}`} data-aos="slide-right">
      <h2>{children}</h2>
    </div>
  );
};

export default TitleBlock;
