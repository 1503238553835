import React from "react";
import UglowionyImg from "./uglowiony/uglowiony.jpg";
import DreamImg from "./dream/dream.jpg";
import KlatkaImg from "./klatka/klatka.jpg";
import AbakanImg from "./abakan/abakan.jpg";
import PlecyImg from "./plecy/plecy.jpg";
import EmbriologiaImg from "./embriologia/embriologia.jpg";
import GhostImg from "./ghost/ghost.jpg";
import MedrcyImg from "./medrcy/medrcy.jpg";
import PostacieSiedzaceImg from "./postacie-siedzace/postacie-siedzace.jpg";

import TlumImg from "./tlum/tlum.jpg";
import MutantImg from "./mutant/mutant.jpg";
import ModelAbakan from "./abakan/abakan";
import ModelPostacieSiedzace from "./postacie-siedzace/postacie-siedzace";
import ModelKlatka from "./klatka/klatka";
import ModelMutant from "./mutant/mutant";
import ModelUglowiony from "./uglowiony/uglowiony";
import ModelTlum from "./tlum/tlum";
import ModelPlecy from "./plecy/plecy";
import ModelDream from "./dream/dream";
import ModelEmbriologia from "./embriologia/embriologia";
import ModelGhost from "./ghost/ghost";
import ModelMedrcy from "./medrcy/medrcy";

export interface ModelDto {
  url: string;
  name: string;
  component: React.ReactNode;
  previewImg: string;
}

const models = [
  {
    url: "abakan",
    name: "Abakan",
    component: <ModelAbakan />,
    previewImg: AbakanImg,
  },
  {
    url: "tlum",
    name: "Tłum",
    component: <ModelTlum />,
    previewImg: TlumImg,
  },
  // {
  //   url: "ptaki",
  //   name: "Ptaki",
  //   component: <ModelPtaki />,
  //   previewImg: PtakiImg,
  // },
  {
    url: "plecy",
    name: "Plecy",
    component: <ModelPlecy />,
    previewImg: PlecyImg,
  },
  {
    url: "klatka",
    name: "Klatka",
    component: <ModelKlatka />,
    previewImg: KlatkaImg,
  },
  {
    url: "postacie-siedzace",
    name: "Postacie siedzące",
    component: <ModelPostacieSiedzace />,
    previewImg: PostacieSiedzaceImg,
  },
  {
    url: "mutanty",
    name: "Mutanty",
    component: <ModelMutant />,
    previewImg: MutantImg,
  },

  {
    url: "uglowiony",
    name: "Ugłowiony",
    component: <ModelUglowiony />,
    previewImg: UglowionyImg,
  },
  {
    url: "dream",
    name: "Dream",
    component: <ModelDream />,
    previewImg: DreamImg,
  },
  {
    url: "embriologia",
    name: "Embriologia",
    component: <ModelEmbriologia/>,
    previewImg: EmbriologiaImg,
  },

  {
    url: "ghost",
    name: "Ghost",
    component: <ModelGhost/>,
    previewImg: GhostImg,
  },

  {
    url: "medrcy",
    name: "Mędrcy",
    component: <ModelMedrcy/>,
    previewImg: MedrcyImg,
  }
] as Array<ModelDto>;

export default models;
