import React from "react";
import Container from "../../../../components/container/container";
import Col from "../../../../components/grid/col";
import Grid from "../../../../components/grid/grid";
import Row from "../../../../components/grid/row";
import TextBlock from "../../../../components/writing-tools/text-block/text-block";
import "./about-section.scss";
import ColorSection from "../../../../components/color-section/color-section";
import ExampleImgLarge from "../../../../assets/akapit1.png";
import ExampleImgLarge3 from "../../../../assets/akapit3.jpg";
import OProjekcieImg from "../../../../assets/o-projekcie.png";

import TopLink from "../../../../components/top-link/top-link";
import { RoutePath } from "../../../../route-paths";
import ImgtextBlock from "../../../../components/img-text-block/img-text-block";

interface Props {
  className?: string;
}

const AboutSection: React.FC<Props> = (props: Props) => {
  const { className } = props;

  return (
    <ColorSection className={`about-section ${className ? className : ""}`} dataAos="fade-up" color="white">
      <Container>
        <Grid>
          <Row gap={96}>
            <Col size={[6, 6, 12, 12]}>
              <TextBlock className="pb-md-5 mb-md-5 pb-3 mb-3">
                <div>
                  <TopLink to={RoutePath.HOME} className="more-link mb-4">
                    &lt; Powrót na stronę główną
                  </TopLink>
                </div>
                <div data-aos="fade-up">
                  <h1 className="title">O projekcie</h1>
                </div>
              </TextBlock>
              <ImgtextBlock img={OProjekcieImg} alt="Żółte abakany z naniesionym logo" aosDelay={0} className="pt-mb-5 mt-mb-5 pt-3 mt-3">
                <p className="pt-3">
                  Projekt zakłada digitalizację 25 unikatowych prac/rzeźb wybitnej, polskiej artystki - Magdaleny Abakanowicz, ze zbiorów Muzeum Narodowego we Wrocławiu oraz upowszechnienie dostępu do eksponatów muzealnych, poprzez przygotowanie atrakcyjnych, wirtualnych materiałów edukacyjnych wykorzystujących zdigitalizowane zasoby.
                  <b />
                  <br />
                  <br />
                  Działania fundacji “Collect” będą również służyły niwelowaniu barier w dostępie do kultury osób niepełnosprawnych w szczególności niepełnosprawnych ruchowo oraz wzrokowo. Zeskanowanie rzeźb pozwoliło nam pozyskać modele 3d, dzięki którym możliwe było wydrukowanie ich przy użyciu drukarek 3d. Wydrukowane miniatury rzeźb pozwalają doświadczać dzieł Magdaleny Abakanowicz w niedostępny wcześniej sposób, a mianowicie poprzez dotyk - niezwykle istotny dla osób z niepełnosprawnością wzrokową. Dzięki aplikacji rozszerzonej rzeczywistości, osoby z ograniczeniami motorycznymi, przy użyciu telefonu lub komputera będą natomiast mogły obejrzeć wirtualne kopie rzeźb w dowolnym miejscu, bez konieczności przemieszczania się.
                  <br />
                  <br />
                  Ponadto dzięki wsparciu “Centrum Innowacji Przejście” postaramy przyczynić się także do zminimalizowania poczucia wykluczenia technologicznego. Wśród wielu działań mamy zaplanowane również warsztaty stacjonarne z wykorzystaniem infrastruktury technologicznej partnerów. Zdigitalizowanie i upowszechnienie powyższych dzieł przyczyni się do promocji spuścizny artystycznej jednej z czołowych przedstawicielek awangardy, należącej do grona najlepiej znanych polskich artystów na świecie.
                </p>
              </ImgtextBlock>
            </Col>
            <Col size={[6, 6, 12, 12]} className="pt-4">
              <ImgtextBlock img={ExampleImgLarge} alt="Fioletowa tkanina z logotypami partnerów i napisem 3D Experience" aosDelay={0}>
                <p className="pt-3">
                  <strong>Abakanowicz 3D</strong> to projekt realizowany przez Fundację Interaktywne Centrum Poznawcze Collect, we współpracy z Muzeum Narodowym we Wrocławiu - Pawilon Czterech Kopuł oraz Centrum Innowacji Przejście, dofinansowany przez Ministerstwo Kultury, Dziedzictwa Narodowego i Sportu w ramach programu <strong>Kultura Cyfrowa</strong>.
                </p>
              </ImgtextBlock>
              <ImgtextBlock img={ExampleImgLarge3} alt="Tłum oraz Magdalena Abakanowicz" aosDelay={0} className="pt-5">
                <p className="pt-3">
                  Głównym celem projektu jest wsparcie sektora edukacyjnego oraz kulturalnego dzięki użyciu współczesnych technologii, które pozwalają na przygotowanie ciekawych, interaktywnych i angażujących materiałów edukacyjnych. W ramach działań projektowych przygotowaliśmy dwie angażujące ścieżki edukacyjne, aplikację rozszerzonej rzeczywistości oraz prezentacje edukacyjne do wykorzystania w nauce zdalnej.
                  <br />
                  <br />
                  <strong>Wszystkie przygotowane przez nas elementy można pobrać na niniejszej stronie projektowej.</strong>
                </p>
              </ImgtextBlock>
            </Col>
          </Row>
        </Grid>
      </Container>
    </ColorSection>
  );
};

export default AboutSection;
