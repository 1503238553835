import React from "react";
import Col from "../../../components/grid/col";
import Row from "../../../components/grid/row";
import TextBlock from "../../../components/writing-tools/text-block/text-block";
import Prefab from "../../../pages/model-page/sections/model-section/prefab";
import ModelWrapper from "../model-wrapper";
import TlumAudio from "./tlum.ogg";

interface Props {
  className?: string;
}

const ModelTlum: React.FC<Props> = (props: Props) => {
  const { className } = props;

  return (
    <ModelWrapper className={className}>
      <Row>
        <Col size={[12, 12, 12, 12]}>
          <Prefab src="https://sketchfab.com/3d-models/tum-bbc051758f344ab7acd4d7c1beebcd27/embed" />
          <TextBlock className="pt-3">
            <h1 className="subtitle pb-4 thin">
              <b>Tłum,</b>&nbsp;1986- 1994
            </h1>
          </TextBlock>
        </Col>
      </Row>
      <Row>
        <Col size={[6, 6, 12, 12]} className="pt-3">
          <Row>
            <Col size={[8, 8, 12, 12]}>
              <TextBlock>
                <p>Figury z cyklu Tłum tworzone w latach 1986- 1994.</p>
                <h2 className="pt-3">
                  <b>Wymiary figury:</b>
                </h2>
                <p>
                  wysokość - 175 cm <br />
                  szerokość - 55 cm <br />
                  głębokość - 30 cm
                </p>
              </TextBlock>
            </Col>
            <Col size={[4, 4, 12, 12]}>
              <TextBlock>
                <h2 className="pt-3">
                  <b>Materiał:</b>
                </h2>
                <p>tkanina jutowa, żywica.</p>
              </TextBlock>
            </Col>
          </Row>
          <Row>
            <Col size={[12, 12, 12, 12]} className="pt-3">
              <TextBlock>
                <p>Dzieło ze zbiorów Muzeum Narodowego we Wrocławiu prezentowane na wystawie stałej w Pawilonie Czterech Kopuł.</p>
              </TextBlock>
            </Col>
          </Row>
        </Col>
        <Col size={[6, 6, 12, 12]} className="pt-3">
          <TextBlock>
            <p className="title-audio">
              <b>Posłuchaj opisu:</b>
              <audio autoPlay={false} controls controlsList="nofullscreen nodownload noremoteplayback noplaybackrate">
                <source src={TlumAudio} type="audio/ogg" />
                Your browser does not support the audio tag.
              </audio>
            </p>
            <p>
              Cykl składa się z 26 rzeźb. Przedstawiają stojące, męskie postacie bez głów. Magdalena Abakanowicz swoje postacie odciskała z form. Płótno świeżo nasączone żywicami układała w ich wnętrzu i w ten sposób nadawała mu zaplanowany kształt. <br />
              <br />"<i>Znajomość tłumu - tego wielbiącego wodzów, którzy okazali się mordercami mas, który w popłochu tratuje ludzi - może stać się twórczą inspiracją, bo jest ciężarem, od którego trudno się wyzwolić. Tworząc własne nieruchome tłumy, zaklinam te istniejące.</i>"<br />- Magdalena Abakanowicz.
              <br /> <br />
              Artystka w latach siedemdziesiątych zmieniła skalę, technikę i tworzywo swych dzieł. Zrealizowała ogromne cykle rzeźb z tkaniny jutowej i żywicy, które określiła mianem - „Alteracje”. Termin alteracja oznacza wzburzenie, niepokój.
              <br />I rzeczywiście, obiekty te są zarówno wynikiem przemyśleń autorki nad losem człowieka jak i odbiciem niepokojów egzystencjalnych nas wszystkich. Postacie ludzkie — pozbawione głów, wykonane z szarego płótna workowego ustawione obok siebie w karnych szeregach są symbolami życia człowieka we współczesnej cywilizacji. Dla jednych jest to szary tłum realnego socjalizmu z jego zamknięciem na świat, uniformizacją, totalitarną dyscypliną, dla innych, opis kondycji ludzkiej o uniwersalnym charakterze.
              <br />
              <br />
              Analiza pracy Tłum, również za pomocą skanu, pozwoli poszerzyć kontekst rozważań nad kondycją ludzką w ogóle oraz pokazać w jaki sposób użyte przez artystkę środki plastyczne uwypuklają egzystencjalny problem zawarty w tytule dzieła.
            </p>
          </TextBlock>
        </Col>
      </Row>
    </ModelWrapper>
  );
};

export default ModelTlum;
