import React from "react";
import Container from "../../../../components/container/container";
import Col from "../../../../components/grid/col";
import Grid from "../../../../components/grid/grid";
import Row from "../../../../components/grid/row";
import TextBlock from "../../../../components/writing-tools/text-block/text-block";
import ColorSection from "../../../../components/color-section/color-section";
import TopLink from "../../../../components/top-link/top-link";
import { RoutePath } from "../../../../route-paths";

interface Props {
  className?: string;
}

const NoNewsResult: React.FC<Props> = (props: Props) => {
  const { className } = props;

  return (
    <ColorSection className={`model-section ${className ? className : ""}`} dataAos="fade-up" color="white">
      <Container>
        <Grid>
          <Row>
            <Col size={[12, 12, 12, 12]}>
              <TextBlock className="pb-5">
                <div>
                  <TopLink to={RoutePath.HOME} className="more-link">
                    &lt; Powrót do strony głównej
                  </TopLink>
                </div>
              </TextBlock>
            </Col>
          </Row>
          <Row>
            <Col size={[6, 6, 12, 12]} className="pt-3 text-center">
              <TextBlock>
                <p className="subtitle pb-4">Błędny adres strony</p>
              </TextBlock>
            </Col>
          </Row>
        </Grid>
      </Container>
    </ColorSection>
  );
};

export default NoNewsResult;
