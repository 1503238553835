import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReduxBg from "../redux/bg-store";
import useSelect from "./useSelect";
import useTheme from "./useTheme";

var themee = "";
var timeoutt: any = null;
export default function useScrollBgChanger(color: string, ref: any) {
  const theme = useTheme();
  const location = useLocation();
  const bgColor = useSelect(ReduxBg.prefix, 'bg-color');

  const handleScroll = () => {
    if (ref && ref.current && ref.current.getBoundingClientRect().top < window.innerHeight / 1.5 && ref.current.getBoundingClientRect().top > window.innerHeight / 1.5 - ref.current!.getBoundingClientRect().height) {
      setColor();
    }
  }

  const setColor = () => {
    if (themee === 'dark') {
      if (bgColor?.hex !== 'black') {
        ReduxBg.Actions.setBgColor('black');
      }
    }
    else if (bgColor?.hex !== color) {
      ReduxBg.Actions.setBgColor(color);
    }
  }

  useEffect(() => {
    themee = theme;
    handleScroll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, theme])

  useEffect(() => {
    // setColor();
    timeoutt = setTimeout(() => {
      handleScroll();
    }, 2100);
    // setColor();

    window.addEventListener("touchmove", handleScroll);
    window.addEventListener("scroll", handleScroll);
    return () => {
      if (timeoutt) {
        clearTimeout(timeoutt);
      }
      window.removeEventListener("touchmove", handleScroll);
      window.removeEventListener("scroll", handleScroll);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   handleScroll();
  //   setTimeout(() => {
  //     handleScroll();
  //   }, 300);
  //   setTimeout(() => {
  //     handleScroll();
  //   }, 1000);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [slideOverlayState]) // detect change page
}