import React from "react";
import PawilonAImg from "../../../../../assets/warsztaty/pawilon/1.jpg";
import PawilonBImg from "../../../../../assets/warsztaty/pawilon/2.jpg";
import PawilonCImg from "../../../../../assets/warsztaty/pawilon/3.jpg";
import ImageGallery from 'react-image-gallery';

interface Props {
  id: string;
}

const WarsztatyPawilon: React.FC<Props> = (props: Props) => {
  const images = [
    {
      img: PawilonAImg,
      alt: "Zdjęcie z pawilonu 1",
    },
    {
      img: PawilonBImg,
      alt: "Zdjęcie z pawilonu 2",
    },
    {
      img: PawilonCImg,
      alt: "Zdjęcie z pawilonu 3",
    },
  ];

  return <ImageGallery items={[...images.map((x) => { return { original: x.img } })]} />;
};

export default WarsztatyPawilon;
