import { Store } from "redux";
import { globalStore } from "../index";

var initialState = {
  theme: localStorage.getItem(window.location.hostname + ':theme') ? localStorage.getItem(window.location.hostname + ':theme') : 'light'
}

class Actions {
  static setTheme = (color?: 'light' | 'dark') => {
    let newColor = ""
    if (color === null || color === undefined) {
      newColor = localStorage.getItem(window.location.hostname + ':theme') === 'dark' || localStorage.getItem(window.location.hostname + ':theme') === undefined ? 'light' : 'dark'
    } else newColor = color!;
    localStorage.setItem(window.location.hostname + ':theme', newColor);
    globalStore.DispatchAction(
      ReduxTheme.prefix,
      {
        type: ReduxTheme.prefix + "Theme",
        payload: newColor
      }
    )
  }
}

class Reducer {
  static Register = (state = initialState, action: any) => {
    if (action.type === ReduxTheme.prefix + "Theme")
      return { ...state, theme: action.payload };
    return state;
  }
}

class Init {
  static Register = () => {
    ReduxTheme.localStore = globalStore.CreateStore(ReduxTheme.prefix, Reducer.Register, []);

    globalStore.RegisterGlobalActions(ReduxTheme.prefix, [
      ReduxTheme.prefix + "Theme",
    ]);
  }
}

class ReduxTheme {
  static prefix = "ReduxTheme_";
  static localStore: Store<any, any>;
  Init = Init;
  static Actions = Actions;
}

export default ReduxTheme;