import React from "react";
import "./grid.scss";

interface Props {
  children: React.ReactNode,
  gap?: 128 | 96 | 64 | 32 | 24 | 16 | 0,
  className?: string;
  align?: 'center'
}

const Row: React.FC<Props> = (props: Props) => {
  return (
    <div className={`row row-gap-${props.gap || props.gap === 0 ? props.gap : 64}${props.className ? " " + props.className : ""}${props.align ? ' align-center' : ''}`}>
      {props.children}
    </div>
  )
}

export default Row;