import React from "react";
import "./news-swiper.scss";
import TopLink from "../../../../../components/top-link/top-link";

interface Props {
  alt: string;
  date: string;
  img?: string;
  children: React.ReactNode;
  offset?: number;
  to?: string;
}

const NewsTile: React.FC<Props> = (props: Props) => {
  const { img, alt, children, date, offset, to } = props;

  return (
    <div className="news-block-wrapper" aria-label={alt}>
      <div className="news-block" data-aos="fade-up" data-aos-delay={offset?.toString()} data-aos-offset="200">
        <div className="news-block__content" data-aos="slide-up" data-aos-delay={offset?.toString()} data-aos-offset="400">
          <p className="mb-2">{date}</p>
          {img ? <div data-aos="fade-up" className="img" role="img"  style={{ backgroundImage: `url(${img})` }} title={alt}></div> : null }
          <p>
            {children}&nbsp;
            {to ? (
              <TopLink to={to} className="more-link" label={alt}>
                Dalej&nbsp;&gt;
              </TopLink>
            ) : null}
          </p>
        </div>
      </div>
    </div>
  );
};

export default NewsTile;
