import React from "react";
import "./img-text-popup-block.scss";
import TextBlock from "../writing-tools/text-block/text-block";
import PlusIcon from "./../../assets/gallery_popup_icon.svg";
import PlayIcon from "./../../assets/play_icon.svg";
import ReduxPopup from "../../redux/popup-store";

interface Props {
  alt: string;
  img: string;
  children?: React.ReactNode;
  aosDelay?: number;
  className?: string;
  fullWidth?: boolean;
  type?: "gallery" | "play";
  popupContent?: React.ReactNode;
  id?: string;
}

const ImgtextPopupBlock: React.FC<Props> = (props: Props) => {
  const { img, alt, children, aosDelay = 0, className = "", fullWidth = false, type = "gallery", popupContent = <></>, id = "any" } = props;

  const openPopup = () => {
    if (type === "gallery") {
      ReduxPopup.Actions.setPopupData(true, popupContent, 'large');
    } else {
      ReduxPopup.Actions.setPopupData(true, popupContent);
    }
  };

  return (
    <>
      <div className={`img-text-popup-block-wrapper ${fullWidth ? "fullWidth" : ""}`} data-aos-delay={aosDelay} data-aos="slide-up">
        <div className={`img-text-popup-block ${className}`}>
          <div className="img-text-popup-block__imgWrapper" onClick={openPopup}>
            <img className="img-text-popup-block__img" src={img} alt={alt} />
            {type === "gallery" ? <img className="img-text-popup-block__icon" src={PlusIcon} alt="Otwórz" /> : null}
            {type === "play" ? <img className="img-text-popup-block__icon" src={PlayIcon} alt="Otwórz" /> : null}
          </div>
          {children ? <TextBlock className="img-text-popup-block__content">{children}</TextBlock> : null}
        </div>
      </div>
      {type === "gallery" ? <div id={id} className="hiddenGallery">{popupContent}</div> : null}
    </>
  );
};

export default ImgtextPopupBlock;
