import React from "react";
import FullImgBlock from "../../../../components/fullImg-block/fullImg-block";
import Col from "../../../../components/grid/col";
import Grid from "../../../../components/grid/grid";
import Row from "../../../../components/grid/row";
import TextBlock from "../../../../components/writing-tools/text-block/text-block";
import "./GetAppSection.scss";
import ARImg from "../../../../assets/aplikacja-ar/mobile.png";
import Container from "../../../../components/container/container";
import ColorSection from "../../../../components/color-section/color-section";
interface GetAppSectionProps {}

const GetAppSection: React.FC<GetAppSectionProps> = (props) => {
  return (
    <ColorSection className={`GetAppSection mb-4 pb-5`} dataAos="fade-up" color="white">
      <Container>
        <Grid>
          <Row>
            <Col size={[6, 8, 12, 12]} offset={[3, 2, 0, 0]}>
              <TextBlock className="pb-4 center">
                <h2 className="title " data-aos="fade-up">
                  <b>Ściągnij aplikację Abakanowicz&nbsp;AR</b>
                </h2>
              </TextBlock>
            </Col>
          </Row>
          <Row className="my-3 py-3 pb-4 mb-4" align="center">
            <Col size={[6, 6, 6, 12]} className="center">
              <a data-aos="fade-up" href="https://play.google.com/store/apps/details?id=pl.rtprog.abakanowicz">
                <FullImgBlock img={ARImg} alt="Pobierz" />
              </a>
              <TextBlock className="center minus-mt-5">
                <a data-aos="fade-up" href="https://play.google.com/store/apps/details?id=pl.rtprog.abakanowicz" className="more-link download-link center">
                  Google Play &gt;
                </a>
              </TextBlock>
            </Col>

            <Col size={[6, 6, 6, 12]}>
              <TextBlock>
                <p>Przygotowana przez nas aplikacja pozwala na obejrzenie wszystkich zdigitalizowanych dotychczas eksponatów ze zbiorów Muzeum Narodowego we Wrocławiu. Na ten moment są to:</p>
                <p className="center py-4">
                  "Abakan Żółty" <br />
                  "Tłum"
                  <br />
                  "Klatka"
                  <br />
                  "Plecy" <br />
                  "Postacie siedzące"
                  <br />
                  "Mutanty"
                  <br />
                  "Dream II"
                  <br />
                  "Ugłowiony"
                  <br />
                </p>
                <p>Liczymy na to, że najbliższych latach uda nam się poszerzyć tę kolekcje o pozostałe zbiory przechowywane w Pawilonie Czterech Kopuł!</p>
              </TextBlock>
            </Col>
          </Row>
        </Grid>
      </Container>
    </ColorSection>
  );
};

export default GetAppSection;
