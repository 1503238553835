import React from "react";
import Container from "../../../../components/container/container";
import Col from "../../../../components/grid/col";
import Grid from "../../../../components/grid/grid";
import Row from "../../../../components/grid/row";
import TextBlock from "../../../../components/writing-tools/text-block/text-block";
import "./aplikacja-ar-section.scss";
import ColorSection from "../../../../components/color-section/color-section";
import TopLink from "../../../../components/top-link/top-link";
import { RoutePath } from "../../../../route-paths";
import AplikacjaAImg from "../../../../assets/aplikacja-ar/1.jpg";
import AplikacjaBImg from "../../../../assets/aplikacja-ar/2.jpg";
import AplikacjaCImg from "../../../../assets/aplikacja-ar/3.jpg";
import FullImgBlock from "../../../../components/fullImg-block/fullImg-block";

interface Props {
  className?: string;
}

const AplikacjaARSection: React.FC<Props> = (props: Props) => {
  const { className } = props;

  return (
    <ColorSection className={`aplikacja-ar-section mb-5 ${className ? className : ""}`} dataAos="fade-up" color="white">
      <Container>
        <Grid>
          <Row>
            <Col size={[6, 6, 12, 12]}>
              <TextBlock className="pb-4">
                <div>
                  <TopLink to={RoutePath.HOME} className="more-link mb-4">
                    &lt; Powrót na stronę główną
                  </TopLink>
                </div>
                <h1 className="title" data-aos="fade-up">
                  <b>Aplikacja AR</b>
                </h1>
              </TextBlock>
            </Col>
          </Row>

          <Row className="my-4 py-4" align="center">
            <Col size={[6, 6, 12, 12]}>
              <TextBlock>
                <FullImgBlock img={AplikacjaAImg} alt="Aplikacja AR" />
              </TextBlock>
            </Col>
            <Col size={[6, 6, 12, 12]}>
              <TextBlock>
                <p>Aplikacja pozwala odkryć twórczość wybitnej polskiej artystki Magdaleny Abakanowicz. Dzięki aplikacji możesz obejrzeć zdigitalizowane eksponaty 3D, przeczytać dodatkowe informacje i urządzić ekspozycję w swoim domu.</p>
                <p>Ponadto nauczyciele i edukatorzy mogą przy jej pomocy tworzyć materiały edukacyjne o zupełnie nowym wymiarze! Trójwymiarowe kopie realnych rzeźb osadzane, oglądane i omawiane wspólnie podczas zajęć pozwalają na pobudzenie wyobraźni i zachęcenie do późniejszego obcowania z realną sztuką. </p>
              </TextBlock>
            </Col>
          </Row>

          <Row className="my-4 py-4 desktop-reverse" align="center">
            <Col size={[6, 6, 12, 12]}>
              <TextBlock>
                <FullImgBlock img={AplikacjaCImg} alt="Aplikacja AR" />
              </TextBlock>
            </Col>
            <Col size={[6, 6, 12, 12]}>
              <TextBlock>
                <p>Omawiane obiekty przestają być tylko słowami i obrazami, bo nabierają wymiarów i przestrzenności, przez co dają wrażenie bliższego obcowania z omawianym przedmiotem. Do pełni doświadczenia brakuje nam wtedy już tylko wrażeń sensorycznych, co zachęca odbiorów do doświadczenia ich osobiście, na miejscu - w muzeum. Aplikacja pomaga w przekazywaniu wiedzy w nowoczesny i nienudny sposób, wzbudza ciekawość uczniów i zachęca do porównania wrażeń wirtualnych z wrażeniami realnymi.</p>
              </TextBlock>
            </Col>
          </Row>

          <Row className="my-4 py-4" align="center">
            <Col size={[6, 6, 12, 12]}>
              <TextBlock>
                <FullImgBlock img={AplikacjaBImg} alt="Aplikacja AR" />
              </TextBlock>
            </Col>
            <Col size={[6, 6, 12, 12]}>
              <TextBlock>
                <p>W przypadku osób z niepełnosprawnościami ruchowymi nasza aplikacja pozwala na niespotykany do tej pory wymiar przybliżenia dzieła odbiorcom. Zależy nam na tym, aby każdy mógł w jak najrówniejszym stopniu docierać do dorobku kultury i sztuki.</p>
                <p>Aplikacja na stałe została włączona do oferty warsztatów edukacyjnych realizowanych w Pawilonie Czterech Kopuł oraz Centrum Innowacji Przejście.</p>
              </TextBlock>
            </Col>
          </Row>
        </Grid>
      </Container>
    </ColorSection>
  );
};

export default AplikacjaARSection;
