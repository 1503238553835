import React from "react";
import Col from "../../../components/grid/col";
import Row from "../../../components/grid/row";
import TextBlock from "../../../components/writing-tools/text-block/text-block";
import Prefab from "../../../pages/model-page/sections/model-section/prefab";
import ModelWrapper from "../model-wrapper";
import DreamAudio from "./ghost.ogg";

interface Props {
  className?: string;
}

const ModelGhost: React.FC<Props> = (props: Props) => {
  const { className } = props;

  return (
    <ModelWrapper className={className}>
      <Row>
        <Col size={[12, 12, 12, 12]}>
          <Prefab src="https://sketchfab.com/models/85da649f56114fe8bbc4247fa70a1b21/embed" />
          <TextBlock className="pt-3">
            <h1 className="subtitle pb-4 thin">
              <b>6 Figur z cyklu Ghost, Spooks, Spirits </b>&nbsp;2008
            </h1>
          </TextBlock>
        </Col>
      </Row>
      <Row>
        <Col size={[6, 6, 12, 12]} className="pt-3">
          <Row>
            <Col size={[8, 8, 12, 12]}>
              <TextBlock>
                <p>
                  {" "}
                  6 Figur z cyklu “Ghost, Spooks, Spirits,” stworzone przez
                  Magdalenę Abakanowicz, w roku 2008
                </p>
                <h2 className="pt-3">
                  <b>Wymiary figury:</b>
                </h2>
                <p>
                  wysokość - 172 cm <br />
                  szerokość - 43 cm <br />
                  głębokość - 51 cm  (3 figury) oraz 59 cm (3 figury)
                </p>
              </TextBlock>
            </Col>
            <Col size={[4, 4, 12, 12]}>
              <TextBlock>
                <h2 className="pt-3">
                  <b>Materiał:</b>
                </h2>
                <p>tkanina jutowa utwardzana żywicą</p>
              </TextBlock>
            </Col>
          </Row>
          <Row>
            <Col size={[12, 12, 12, 12]} className="pt-3">
              <TextBlock>
                <p>
                  Dzieło ze zbiorów Muzeum Narodowego we Wrocławiu. Zakupione od
                  Jana Kosmowskiego w 2017 r., dzięki dotacji Ministerstwa
                  Kultury i Dziedzictwa Narodowego. Prezentowane na wystawie
                  stałej w Pawilonie Czterech Kopuł.
                </p>
              </TextBlock>
            </Col>
          </Row>
        </Col>
        <Col size={[6, 6, 12, 12]} className="pt-3">
          <TextBlock>
            <p className="title-audio">
              <b>Posłuchaj opisu:</b>
              <audio
                autoPlay={false}
                controls
                controlsList="nofullscreen nodownload noremoteplayback noplaybackrate"
              >
                <source src={DreamAudio} type="audio/ogg" />
                Your browser does not support the audio tag.
              </audio>
            </p>
            <p>
              Sześć figur z cyklu Ghost, Spooks, Spirits, to rzeźby kształtem
              przypominające stojące postacie ludzkie z wydrążonym środkiem.
              Zrobione są z pofałdowanej tkaniny jutowej utwardzonej żywicą.
              Wszystkie figury mają masywne, długie nogi bez wyraźnie
              zaznaczonego tułowia. Kończą się kształtem przypominającym leżący
              trójkąt. Dwie figury zwieńczone są trójkątami o ostrych szpicach.
              Cztery pozostałe mają łagodne, półkoliste wierzchołki. Na wystawie
              w Pawilonie ustawione są w dwóch rzędach, w odległości 100
              centymetrów od siebie— 3 figury w pierwszym i 3 figury w drugim
              szeregu. Tytuły rzeźb Ghost, Spooks, Spirits ( po polsku: duch,
              zjawy, dusze) kierują nasze myśli w stronę grozy, przerażenia i
              niepokoju. Do obaw najbardziej zamierzchłych i trwałych w
              zbiorowej wyobraźni artystka dodaje swoje. I tak jak zawsze w jej
              twórczości cielesność i nietrwałość materii uczestniczy w procesie
              ich oswajania.
            </p>
          </TextBlock>
        </Col>
      </Row>
    </ModelWrapper>
  );
};

export default ModelGhost;
