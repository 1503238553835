import React from "react";
import CentrumAImg from "../../../../../assets/warsztaty/centrum/1.jpg";
import CentrumBImg from "../../../../../assets/warsztaty/centrum/2.jpg";
import CentrumCImg from "../../../../../assets/warsztaty/centrum/3.jpg";
import ImageGallery from 'react-image-gallery';

interface Props {
  id: string;
}

const WarsztatyCentrum: React.FC<Props> = (props: Props) => {
  const images = [
    {
      img: CentrumAImg,
      alt: "Zdjęcie z centrum 1",
    },
    {
      img: CentrumBImg,
      alt: "Zdjęcie z centrum 2",
    },
    {
      img: CentrumCImg,
      alt: "Zdjęcie z centrum 3",
    },
  ];

  return <ImageGallery items={[...images.map((x) => { return { original: x.img } })]} />;
};

export default WarsztatyCentrum;
