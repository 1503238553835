import React, { useEffect, useState } from "react";
import "./model-section.scss";

interface Props {
  className?: string;
  src: string;
}

const Prefab: React.FC<Props> = (props: Props) => {
  const { className, src } = props;
  const [loadPrefab, setLoadPrefab] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      setLoadPrefab(1);
    }, 300);
  }, []);
  const loadListener = () => {
    setLoadPrefab(2);
  };

  return (
    <div className={`prefab mb-5 ${loadPrefab > 1 ? "loadPrefab" : ""} ${className}`}>
      <div className="iframe-wrapper">{loadPrefab > 0 ? <iframe sandbox="allow-scripts allow-same-origin allow-popups allow-forms" title="Uglowiony" frameBorder="0" onLoad={() => loadListener()} allowFullScreen allow="autoplay; fullscreen; xr-spatial-tracking" src={src} /> : null}</div>
    </div>
  );
};

export default Prefab;
