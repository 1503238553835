import React from "react";
import NewsTile from "./news-tile";
import "./news-swiper.scss";

import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { RoutePath } from "../../../../../route-paths";
import useTheme from "../../../../../tools/useTheme";
import useWindowSize from "../../../../../tools/useWindowSize";
import { generatePath } from "react-router-dom";
import news, { NewsDto } from "../../../../../data/news/news";
import SwiperNav from "../../../../../components/swiper-nav/swiper-nav";
import { a11yOptions } from "../../../../../tools/a11yOptions";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

const NewsSwiper: React.FC = () => {
  const size = useWindowSize();
  const theme = useTheme();

  return (
    <div className={`news-swiper`} data-aos="slide-up">
      <div className={`news-wrapper ${theme}`}>
        <SwiperNav classNamePrev="swiper-button-prev-news" classNameNext="swiper-button-next-news" className="slider-nav" />
        <div id="livearea-news" aria-live="polite"></div>
        <Swiper
          spaceBetween={size.isMobile ? 64 : 96}
          slidesPerView={size.isMobile ? 1 : 3}
          loop={true}
          navigation={{
            prevEl: ".swiper-button-prev-news",
            nextEl: ".swiper-button-next-news",
          }}
          autoplay={false}
          keyboard={true}
          a11y={a11yOptions}
          onSlideChange={(swiper) => {
            var myslide = swiper.realIndex;
            var swiperLive = document.getElementById("livearea-news");
            if (swiperLive) {
              swiperLive.ariaLabel = `Wyświetlony slajd: ${news[myslide].title}`;
            }
          }}
        >
          {news.map((item: NewsDto, index: number) => {
            return (
              <SwiperSlide key={index}>
                <NewsTile alt={item.title} date={item.date} img={item.previewImg} offset={200 * index} to={generatePath(RoutePath.NEWS, { newsId: item.url })}>
                  {item.title}
                </NewsTile>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default NewsSwiper;
