import React from "react";
import Container from "../../components/container/container";
import Col from "../../components/grid/col";
import Grid from "../../components/grid/grid";
import Row from "../../components/grid/row";
import "./prefooter-section.scss";
import LogoFooter from "./../../assets/logoFooter.svg";
import FooterIconA from "./../../assets/footerIconA.svg";
import FooterIconB from "./../../assets/footerIconB.svg";
import FooterIconC from "./../../assets/footerIconC.svg";
import ColorSection from "../../components/color-section/color-section";
import useTheme from "../../tools/useTheme";

const PrefooterSection: React.FC = () => {
  const theme = useTheme();

  return (
    <ColorSection className={`prefooter-section`} color="#FFAF21">
      <Container className={`${theme}`}>
        <Grid>
          <Row gap={16}>
            <Col size={[3, 3, 12, 12]} className="company-col">
              <h2>
                <img src={LogoFooter} alt="Abakanowicz logo" data-aos="slide-up" />
              </h2>
              <div className="pt-xl-4 pt-0 mt-3" data-aos="slide-up">
                <a href="https://www.instagram.com/abakanowicz3d" target="_blank" rel="noreferrer">
                  Instagram
                </a>{" "}
                <br />
                <a href="https://www.facebook.com/Abakanowicz3D" target="_blank" rel="noreferrer">
                  Facebook
                </a>
              </div>
            </Col>
            <Col size={[8, 8, 12, 12]} offset={[1, 0, 0, 0]} className="info-col">
              <Row gap={16}>
                <Col size={[4, 4, 4, 12]}>
                  <div className="col-title" data-aos="slide-up">
                    <div className="icon">
                      <img src={FooterIconA} alt="Fundacja Interaktywne Centrum Poznawcze Collect" data-aos="slide-up" />
                    </div>
                    <h3 data-aos="slide-up">Fundacja Interaktywne Centrum Poznawcze Collect</h3>
                  </div>
                  <div className="col-content" data-aos="slide-up">
                    <p>
                      Gen. Romualda Traugutta 66/11, <br /> 50-418 Wrocław, Polska
                    </p>
                    <p>
                      <a href="tel:+48730820009">+48 730 820 009</a> <br />
                      <a href="mailto:maciek.mosur@gmail.com">maciek.mosur@gmail.com</a>
                    </p>
                  </div>
                </Col>
                <Col size={[4, 4, 4, 12]}>
                  <div className="col-title" data-aos="slide-up">
                    <div className="icon">
                      <img src={FooterIconB} alt="Muzeum Narodowe we Wrocławiu" data-aos="slide-up" />
                    </div>
                    <h3 data-aos="slide-up">Muzeum Narodowe we Wrocławiu</h3>
                  </div>
                  <div className="col-content" data-aos="slide-up">
                    <p>
                      plac Powstańców Warszawy 5, <br />
                      50-153 Wrocław
                    </p>
                    <p>
                      <a href="tel:+48713435643">+48 71 343 56 43</a> <br />
                      <a href="mailto:sekretariat@mnwr.pl">sekretariat@mnwr.pl</a>
                    </p>
                  </div>
                </Col>
                <Col size={[4, 4, 4, 12]}>
                  <div className="col-title" data-aos="slide-up">
                    <div className="icon">
                      <img src={FooterIconC} alt="Ministerstwo Kultury Dziedzictwa Narodowego i Sportu" data-aos="slide-up" />
                    </div>
                    <h3 data-aos="slide-up">Ministerstwo Kultury Dziedzictwa Narodowego i Sportu</h3>
                  </div>
                  <div className="col-content" data-aos="slide-up">
                    <p>Dofinansowano ze środków Ministra Kultury, Dziedzictwa Narodowego i Sportu pochodzących z Funduszu Promocji Kultury - państwowego funduszu celowego.</p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Grid>
      </Container>
    </ColorSection>
  );
};

export default PrefooterSection;
