import React from "react";
import Col from "../../../components/grid/col";
import Row from "../../../components/grid/row";
import TextBlock from "../../../components/writing-tools/text-block/text-block";
import Prefab from "../../../pages/model-page/sections/model-section/prefab";
import ModelWrapper from "../model-wrapper";
import LoremAudio from "./plecy audiodeskrypcja.ogg";

interface Props {
  className?: string;
}

const ModelPlecy: React.FC<Props> = (props: Props) => {
  const { className } = props;

  return (
    <ModelWrapper className={className}>
      <Row>
        <Col size={[12, 12, 12, 12]}>
          <Prefab src="https://sketchfab.com/models/59b97b759531444a8add069bbd9fbb18/embed" />
          <TextBlock className="pt-3">
            <h1 className="subtitle pb-4 thin">
              <b>Plecy,</b>&nbsp;1976-1980
            </h1>
          </TextBlock>
        </Col>
      </Row>
      <Row>
        <Col size={[6, 6, 12, 12]} className="pt-3">
          <Row>
            <Col size={[8, 8, 12, 12]}>
              <TextBlock>
                <p>Rzeźba Magdaleny Abakanowicz zatytułowana „Plecy”</p>
                <h2 className="pt-3">
                  <b>Wymiary figury:</b>
                </h2>
                <p>
                  wysokość - 69 cm <br />
                  szerokość - 56 cm <br />
                  głębokość - 66 cm
                </p>
              </TextBlock>
            </Col>
            <Col size={[4, 4, 12, 12]}>
              <TextBlock>
                <h2 className="pt-3">
                  <b>Materiał:</b>
                </h2>
                <p>tkanina jutowa, żywica</p>
              </TextBlock>
            </Col>
          </Row>
          <Row>
            <Col size={[12, 12, 12, 12]} className="pt-3">
              <TextBlock>
                <p>
                  W zbiorach Muzeum Narodowego we Wrocławiu znajduje się 26 rzeźb z tej serii.
                  <br />
                  <br />
                  Dzieło ze zbiorów Muzeum Narodowego we Wrocławiu prezentowane na wystawie stałej w Pawilonie Czterech Kopuł.
                </p>
              </TextBlock>
            </Col>
          </Row>
        </Col>
        <Col size={[6, 6, 12, 12]} className="pt-3">
          <TextBlock>
            <p className="title-audio">
              <b>Posłuchaj opisu:</b>
              <audio autoPlay={false} controls controlsList="nofullscreen nodownload noremoteplayback noplaybackrate">
                <source src={LoremAudio} type="audio/ogg" />
                Your browser does not support the audio tag.
              </audio>
            </p>
            <p>
              „Plecy” powstały jako seria 80 figur, które przedstawiają siedzące, przygarbione ludzkie plecy, pozbawione rąk, nóg oraz głowy z tylko lekko zaznaczonymi kończynami.
              <br />
              <br />
              Rzeźba przedstawia odcisk ludzkich pleców, od szyi aż po pośladki. Postać pozbawiona jest rąk, nóg oraz głowy. Przedstawia pustą skorupę w kształcie zgarbionej ludzkiej sylwetki. Figura ta ma wymiary 69 centymetrów wysokość, 56 centymetrów szerokość, 66 centymetrów głębokość. „Plecy”, podobnie jak inne obiekty z cyklu „Alteracje” wykonane została z tkaniny jutowej utwardzanej żywicą. Forma stworzona jest z poprzyklejanych na siebie uciętych fragmentów tkaniny. Struktura pleców jest nieregularna. W niektórych miejscach tkanina jutowa jest przyklejona w gładszy sposób, w innym jest bardziej pofałdowana.
              <br />
              <br />
              „Plecy” przypominają swoimi formami zastygłą w ruchu energię, nawiązują symbolicznie do życia człowieka we współczesnej cywilizacji.
            </p>
          </TextBlock>
        </Col>
      </Row>
    </ModelWrapper>
  );
};

export default ModelPlecy;
