import React, { useEffect, useState } from "react";
import "./navbar.scss";
import Grid from "../grid/grid";
import Row from "../grid/row";
import Col from "../grid/col";
import LogoImg from "../../assets/logo.svg";
import { RoutePath } from "../../route-paths";
import TopLink from "../top-link/top-link";
import Container from "../container/container";
import useWindowSize from "../../tools/useWindowSize";
import SubNavbar from "./subNavbar";
import { globalStore } from "../../index";
import ReduxCallbacks from "../../redux/callback-store";
import MenuBtn from "../menu-btn/menu-btn";
import ContrastBtn from "../contrast-btn/contrast-btn";
import ReduxTheme from "../../redux/contrast-store";
import useTheme from "../../tools/useTheme";
import LangBtn from "../lang-btn/lang-btn";

const Navbar: React.FC = () => {
  const [isMenuOpen, setIsMobileMenuOpen] = useState(false);
  const size = useWindowSize();
  const theme = useTheme();

  useEffect(() => {
    globalStore.Subscribe(ReduxCallbacks.prefix, localStateChanged);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const localStateChanged = () => {
    setIsMobileMenuOpen(false);
  };

  const handleClickMenu = (e: any) => {
    if (isMenuOpen && size.isMobile) {
      setTimeout(() => {
        setIsMobileMenuOpen(false);
      }, 300);
    }
  };

  const handleMenuBtnClick = () => {
    setIsMobileMenuOpen(!isMenuOpen);
  };

  const handleChangeContrast = () => {
    ReduxTheme.Actions.setTheme();
  };

  useEffect(() => {
    if (!size.isMobile) {
      setIsMobileMenuOpen(false);
    }
  }, [size.isMobile]);

  return (
    <div className={`navbar ${theme}`}>
      <Container size="large">
        <Grid>
          <Row gap={16}>
            <Col size={[2, 2, 2, 6]} className="logo">
              <TopLink to={RoutePath.HOME} tabindex={1}>
                <img src={LogoImg} alt="Abakanowicz logo" />
              </TopLink>
            </Col>
            <Col size={[6, 6, 6, 6]} className="menu-col">
              <LangBtn />
              <div className={`navbar__contrast pt-1`} onClick={handleChangeContrast}>
                <ContrastBtn />
              </div>
              <div className={`navbar__menu${isMenuOpen ? " isOpen" : ""}`} onClick={handleMenuBtnClick}>
                <MenuBtn isMenuOpen={isMenuOpen} />
              </div>
            </Col>
          </Row>
        </Grid>
      </Container>
      <div className={`navbar__subnavbar${isMenuOpen ? " isOpen" : ""}`}>{isMenuOpen ? <SubNavbar isOpen={isMenuOpen} onClick={handleClickMenu} /> : null}</div>
    </div>
  );
};

export default Navbar;
