import React from "react";
import Col from "../../../components/grid/col";
import Row from "../../../components/grid/row";
import ImgtextBlock from "../../../components/img-text-block/img-text-block";
import TextBlock from "../../../components/writing-tools/text-block/text-block";
import NewsWrapper from "../news-wrapper";
import DigitalFestivalImg from "./digital-festival.jpg";

interface Props {
  className?: string;
}

const NewsDigitalFestival: React.FC<Props> = (props: Props) => {
  const { className } = props;

  return (
    <NewsWrapper className={className}>
      <Row>
        <Col size={[6, 6, 12, 12]}>
          <TextBlock className="pb-md-5 mb-md-5 pb-3 mb-3">
            <p>
              W ramach 3. Edycji Digital Festival będziemy opowiadać w jaki sposób wpadliśmy na pomysł założenia Fundacji Collect i jaka misja nam przyświeca w bieżących działaniach. Na przykładzie projektu “Abakanowicz 3d” opowiemy również o tym, jak współczesne technologie mogą przysłużyć się sektorowi edukacyjnemu, w jaki sposób pomagają niwelować bariery w dostępie do kultury, czy podnosić kompetencje technologiczne nauczycieli i edukatorów. Zastanowimy się również nad przyszłością dostępnych rozwiązań i idącym za nimi możliwościom we współczesnej edukacji.
              <br />
              <br />
              Wszystko odbędzie się 08.10.2021, od godziny 12 do 14.
              <br />
              <br />
              Zapraszamy na live na naszym profilu fb:{" "}
              <a className="red" href="https://www.facebook.com/Abakanowicz3D" target="_blank" rel="noreferrer">
                facebook.com/Abakanowicz3D
              </a>
            </p>
          </TextBlock>
        </Col>
        <Col size={[6, 6, 12, 12]}>
          <TextBlock className="pb-md-5 mb-md-5 pb-3 mb-3">
            <p>
              źródło:{" "}
              <a className="red" href="https://digitalfestival.pl/wydarzenia/abakanowicz-3d-wykorzystanie-nowych-technologii-w-edukacji-kulturalnej-i-muzealnictwie/" target="_blank" rel="noreferrer">
                digitalfestival.pl
              </a>
            </p>
          </TextBlock>
        </Col>
      </Row>
      <Row gap={32}>
        <Col size={[12, 12, 12, 12]} className="pt-4">
          <ImgtextBlock img={DigitalFestivalImg} alt="Digital Festival - wydarzenie" aosDelay={0}></ImgtextBlock>
        </Col>
      </Row>
    </NewsWrapper>
  );
};

export default NewsDigitalFestival;
