import React from "react";
import Col from "../../../components/grid/col";
import Row from "../../../components/grid/row";
import TextBlock from "../../../components/writing-tools/text-block/text-block";
import Prefab from "../../../pages/model-page/sections/model-section/prefab";
import ModelWrapper from "../model-wrapper";
import EmbriologiaAudio from "./EmbriologiaOpis.ogg";

interface Props {
  className?: string;
}

const ModelEmbriologia: React.FC<Props> = (props: Props) => {
  const { className } = props;

  return (
    <ModelWrapper className={className}>
      <Row>
        <Col size={[12, 12, 12, 12]}>
          <Prefab src="https://sketchfab.com/models/d0fbcfaca55043a39a89758931dac852/embed" />
          <TextBlock className="pt-3">
            <h1 className="subtitle pb-4 thin">
              <b>Embriologia,</b>&nbsp;1978-80
            </h1>
          </TextBlock>
        </Col>
      </Row>
      <Row>
        <Col size={[6, 6, 12, 12]} className="pt-3">
          <Row>
            <Col size={[8, 8, 12, 12]}>
              <TextBlock>
                <p>
                  kompozycja przestrzenna „Embriologia” stworzone przez
                  Magdalenę Abakanowicz w latach 1978-80.
                </p>
                <h2 className="pt-3">
                  <b>Wymiary figury:</b>
                </h2>
                <p>
                  wysokość - od 7 do 50 cm <br />
                  szerokość - od 5 do 200 cm <br />
                </p>
              </TextBlock>
            </Col>
            <Col size={[4, 4, 12, 12]}>
              <TextBlock>
                <h2 className="pt-3">
                  <b>Materiał:</b>
                </h2>
                <p>tkanina jutowa, nylonowa, sznurek, pakuły</p>
              </TextBlock>
            </Col>
          </Row>
          <Row>
            <Col size={[12, 12, 12, 12]} className="pt-3">
              <TextBlock>
                <p>
                  W kolekcji Muzeum Narodowego we Wrocławiu znajduje się 126
                  elementów. Dzieło ze zbiorów Muzeum Narodowego we Wrocławiu
                  prezentowane na wystawie stałej w Pawilonie Czterech Kopuł.
                </p>
              </TextBlock>
            </Col>
          </Row>
        </Col>
        <Col size={[6, 6, 12, 12]} className="pt-3">
          <TextBlock>
            <p className="title-audio">
              <b>Posłuchaj opisu:</b>
              <audio autoPlay={false} controls controlsList="nofullscreen nodownload noremoteplayback noplaybackrate">
                <source src={EmbriologiaAudio} type="audio/ogg" />
                Your browser does not support the audio tag.
              </audio>
  </p>
            <p>
              Kompozycja przestrzenna Embriologia autorstwa Magdaleny
              Abakanowicz została wykonana z tkaniny jutowej, nylonowej, sznurka
              oraz pakuły. Tytułowe formy kształtem przypominają kamienie lub
              ziemniaki. Artystka stworzyła jutowe powłoki, do których następnie
              włożyła wypełnienie. Nie ma więc dwóch jednakowych embrionów. Na
              powierzchni wyraźne są miejsca zszywania poszczególnych tkanin.
              Artystka użyła do ich połączenia sznurka. Gdy prezentowane razem,
              ułożone są w przypadkowych konfiguracjach - mniejszych bądź
              większych grupach. Po raz pierwszy formy z cyklu Embriologia
              Magdalena Abakanowicz pokazała na wystawie zbiorowej Soft-Art" w
              Kunsthaus w Zurychu. Następnie seria ta (składająca się aż z
              sześciuset obiektów) była prezentowana w Pawilonie Polskim na
              Biennale w Wenecji w 1980 roku.
            </p>
          </TextBlock>
        </Col>
      </Row>
    </ModelWrapper>
  );
};

export default ModelEmbriologia;
