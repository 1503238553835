import React from "react";
import Footer from "../../sections/footer/footer";
import PrefooterSection from "../../sections/prefooter-section/prefooter-section";
import SciezkiSection from "./sections/sciezki-section/sciezki-section";
import "./sciezki-page.scss";

const SciezkiPage: React.FC = () => {
  return (
    <div className="sciezki-page">
      <SciezkiSection />
      <br />
      <PrefooterSection />
      <Footer />
    </div>
  );
};

export default SciezkiPage;
