import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import models, { ModelDto } from "../../data/models/models";
import Footer from "../../sections/footer/footer";
import PrefooterSection from "../../sections/prefooter-section/prefooter-section";
import NoModelResult from "./sections/model-section/no-model-result";
import "./model-page.scss";

const ModelPage: React.FC = () => {
  const { modelId }: { modelId: string } = useParams();
  const [model, setModel] = useState<ModelDto | undefined>(undefined);

  useEffect(() => {
    const modelData = models.find((x) => x.url === modelId);
    setModel(modelData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelId]);

  return (
    <div className="model-page">
      {model ? model.component : <NoModelResult />}
      <br />
      <PrefooterSection />
      <Footer />
    </div>
  );
};

export default ModelPage;
