import React, { useRef } from "react";
import useScrollBgChanger from "../../tools/useScrollBgChanger";
import "./color-section.scss";

interface Props {
  children: React.ReactNode;
  className?: string;
  dataAos?: string;
  color?: string;
  style?: any;
}

const ColorSection: React.FC<Props> = (props: Props) => {
  const ref = useRef<any>();
  const { children, className, style, dataAos = "zoom-in", color = "white" } = props;
  useScrollBgChanger(color, ref);

  return (
    <section className={`color-section ${className}`} style={style ? style : null} data-aos={dataAos} ref={ref}>
      {children}
    </section>
  );
};

export default ColorSection;
