import React from "react";
import Container from "../../../../components/container/container";
import Col from "../../../../components/grid/col";
import Grid from "../../../../components/grid/grid";
import Row from "../../../../components/grid/row";
import TextBlock from "../../../../components/writing-tools/text-block/text-block";
import "./warsztaty-section.scss";
import ColorSection from "../../../../components/color-section/color-section";

import WasztatyPawilonImg from "../../../../assets/warsztaty_pawilon.jpg";
import WasztatyCentrumImg from "../../../../assets/warsztaty/centrum/2.jpg";
// import PrezentacjaAImg from "../../../../assets/Abakany_prezka2.jpg";
// import PrezentacjaBImg from "../../../../assets/Abakany_prezka.jpg";

import TopLink from "../../../../components/top-link/top-link";
import { RoutePath } from "../../../../route-paths";
import ImgtextPopupBlock from "../../../../components/img-text-popup-block/img-text-popup-block";
import WarsztatyPawilon from "./popups/warsztaty-pawilon";
import WarsztatyCentrum from "./popups/warsztaty-centrum";

interface Props {
  className?: string;
}

const WarsztatySection: React.FC<Props> = (props: Props) => {
  const { className } = props;

  return (
    <ColorSection className={`warsztaty-section mb-5 ${className ? className : ""}`} dataAos="fade-up" color="white">
      <Container>
        <Grid>
          <Row>
            <Col size={[6, 6, 12, 12]}>
              <TextBlock className="pb-md-5 mb-md-5 pb-3 mb-3">
                <div>
                  <TopLink to={RoutePath.HOME} className="more-link mb-4">
                    &lt; Powrót na stronę główną
                  </TopLink>
                </div>
                <h1 className="title" data-aos="fade-up">
                  Warsztaty
                </h1>
              </TextBlock>
            </Col>
          </Row>
          <Row>
            <Col size={[6, 6, 12, 12]}>
              <TextBlock className="pb-md-5 mb-md-5 pb-3 mb-3">
                <p>Angażujące ścieżki edukacyjne, przygotowane zostały w oparciu o eksponaty, materiały graficzne i obiekty wydrukowane w technologii 3D. W proces poznawczy została również włączona rozszerzona rzeczywistość (Augmented Reality), dzięki której obcowanie z twórczością Magdaleny Abakanowicz stanie się zupełnie nowym doświadczeniem.</p>
              </TextBlock>
            </Col>
            <Col size={[6, 6, 12, 12]}>
              <TextBlock className="pb-md-5 mb-md-5 pb-3 mb-3">
                <p className="pb-md-5">W trakcie zajęć będzie można spotkać „Mutanty”, ale bez obaw - Zespół Edukacyjny Pawilonu Czterech Kopuł opowie o nich wszystko i udowodni, że wcale nie są straszne. Edukatorzy zadbają, by nikt nie zgubił się w „Tłumie” i sprawią, że obcowanie ze sztuką stanie się prawdziwą przygodą!</p>
                <p className="pt-5">
                  <b>Zajęcia realizowane w ramach projektu są bezpłatne.</b>
                </p>
                <p className="questions">
                  Masz więcej pytań dotyczących warsztatów? <br />
                  Prosimy o kontakt: <a href="mailto:edukacja.pawilon@mnwr.pl">edukacja.pawilon@mnwr.pl</a>
                </p>
              </TextBlock>
            </Col>
          </Row>

          <Row className="my-5 py-5">
            <Col size={[6, 6, 12, 12]}>
              <ImgtextPopupBlock img={WasztatyPawilonImg} alt="Warsztaty pawilon" id={"WarsztatyPawilon"} aosDelay={0} fullWidth popupContent={<WarsztatyPawilon id="WarsztatyPawilon" />}>
                <p className="subtitle pt-2">
                  <b>Warsztaty Pawilon</b>
                </p>
                <p>W ramach współpracy z Pawilonem Czterech Kopuł przeprowadziliśmy warsztaty łącznie dla ponad 600 osób. Niesamowitą radość sprawił nam odbiór przygotowanych w ramach projektu Abakanowicz 3D materiałów edukacyjnych: wydruków 3D, aplikacji AR, materiałów warsztatowych. Wśród grup, które uczestniczyły w warsztatach były m.in. osoby niepełnosprawne, dzieci i młodzież z dolnośląskich szkół oraz dorośli. Dodatkowo cykl warsztatowy realizowany w Pawilonie Czterech Kopuł został włączony działania w ramach “Festiwalu Kultura bez barier”.</p>
              </ImgtextPopupBlock>
            </Col>
            <Col size={[6, 6, 12, 12]} className="pt-5 mt-5">
              <ImgtextPopupBlock img={WasztatyCentrumImg} alt="Warsztaty centrum" id={"WarsztatyCentrum"} className="mt-5 pt-5" aosDelay={200} fullWidth popupContent={<WarsztatyCentrum id="WarsztatyCentrum" />}>
                <p className="subtitle pt-2">
                  <b>Warsztaty Centrum</b>
                </p>
                <p>Warsztaty w Centrum Innowacji Przejście były skierowana do szerokiego grona odbiorców. Z zajęć skorzystali zarówno dzieci, jak i młodzież jak i dorośli. Warsztaty cieszyły się tak dużym zainteresowaniem, że zostały włączone do oferty warsztatowej Centrum. Cieszy nas, że z ofertą warsztatową dotarliśmy także do osób niepełnosprawnych oraz wykluczonych społecznie. W ramach współpracy w Centrum Innowacji Przejście przeprowadziliśmy warsztaty dla ponad 170 osób.</p>
              </ImgtextPopupBlock>
            </Col>
          </Row>

          <Row className="mt-5 pt-5">
            <Col size={[8, 10, 12, 12]}>
              <TextBlock>
                <h2 className="title" data-aos="fade-up">
                  Materiały warszatowe do pobrania
                </h2>
              </TextBlock>
            </Col>
          </Row>
          {navigator.userAgent === "ReactSnap" ? (
            <></>
          ) : (
            <>
              <Row className="py-5 my-5" align="center">
                <Col size={[8, 6, 4, 12]}>
                  <TextBlock>
                    {/* <img src={PrezentacjaAImg} alt="Prezentacja Życie i twórczość Wokół prac Magdaleny Abakanowicz" /> */}
                    <iframe className="nearpod" title="Prezentacja Życie i twórczość Wokół prac Magdaleny Abakanowicz" src={`https://app.nearpod.com/?pin=028E37A54A3E57CED222E1BAB38672B7-1&he=true`}></iframe>
                  </TextBlock>
                </Col>
                <Col size={[4, 6, 8, 12]}>
                  <TextBlock>
                    <p className="subtitle high-line justify-center">
                      <a href="https://np1.nearpod.com/sharePresentation.php?code=448e01dc2a2e1fbec4dc070f29531528-1" rel="noreferrer" target="_blank">
                        <b>
                          Życie i twórczość. <br /> Wokół prac Magdaleny Abakanowicz&nbsp;&gt;
                        </b>
                      </a>
                    </p>
                  </TextBlock>
                </Col>
              </Row>

              <Row className="py-5 my-5" align="center">
                <Col size={[4, 6, 8, 12]}>
                  <TextBlock>
                    <p className="subtitle high-line justify-center">
                      <a href="https://np1.nearpod.com/sharePresentation.php?code=74c6de4a27dd238b6f89bfa7d21069e2-1" rel="noreferrer" target="_blank">
                        <b>
                          Odnajdź się w <br /> "Tłumie"&nbsp;&gt;
                        </b>
                      </a>
                    </p>
                  </TextBlock>
                </Col>
                <Col size={[8, 6, 4, 12]}>
                  <TextBlock>
                    {/* <img src={PrezentacjaBImg} alt={`Prezentacja Odnajdź się w "Tłumie"`} /> */}
                    <iframe className="nearpod" title={`Prezentacja Odnajdź się w "Tłumie"`} src={`https://app.nearpod.com/?pin=151351A0FDE8537BEA2191CEE0B6634A-1&he=true`}></iframe>
                  </TextBlock>
                </Col>
              </Row>
              <Row>
                <Col size={[6, 6, 12, 12]}>
                  <TextBlock>
                    <TopLink to={RoutePath.HOME} className="more-link">
                      &lt; Powrót na stronę główną
                    </TopLink>
                  </TextBlock>
                </Col>
              </Row>
            </>
          )}
        </Grid>
      </Container>
    </ColorSection>
  );
};

export default WarsztatySection;
