import React from "react";
import AImg from "../../../../../assets/multimedia/ImmersionFesitwal/1.jpg";
import BImg from "../../../../../assets/multimedia/ImmersionFesitwal/2.jpg";
import CImg from "../../../../../assets/multimedia/ImmersionFesitwal/3.jpg";
import DImg from "../../../../../assets/multimedia/ImmersionFesitwal/4.jpg";
import ImageGallery from 'react-image-gallery';
interface Props {}

const ImmersionFesitwal: React.FC<Props> = (props: Props) => {
  const images = [
    {
      img: AImg,
      alt: "Zdjęcie z Immersion Fesitwal 1",
    },
    {
      img: BImg,
      alt: "Zdjęcie z Immersion Fesitwal 2",
    },
    {
      img: CImg,
      alt: "Zdjęcie z Immersion Fesitwal 3",
    },
    {
      img: DImg,
      alt: "Zdjęcie z Immersion Fesitwal 4",
    },
  ];
  
  return <ImageGallery items={[...images.map((x) => { return { original: x.img } })]} />;
};

export default ImmersionFesitwal;
