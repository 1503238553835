import React from "react";
import Col from "../../../components/grid/col";
import Row from "../../../components/grid/row";
import TextBlock from "../../../components/writing-tools/text-block/text-block";
import NewsWrapper from "../news-wrapper";

interface Props {
  className?: string;
}

const NewsOProcesieSkanowania: React.FC<Props> = (props: Props) => {
  const { className } = props;

  return (
    <NewsWrapper className={className}>
      <Row>
        <Col size={[6, 6, 12, 12]}>
          <TextBlock className="pb-md-5 mb-md-5 pb-3 mb-3">
            <p>Chcielibyście dowiedzieć się więcej o tym jak przenieśliśmy rzeźby w środowisko cyfrowe i poznać dalsze plany związane z projektem? Polecamy posłuchać jak mówią o tym nasi specjaliści!</p>
          </TextBlock>
        </Col>
        <Col size={[6, 6, 12, 12]}>
          <TextBlock className="pb-md-5 mb-md-5 pb-3 mb-3">
            <p>
              <a target="_blank" rel="noreferrer" href="https://echo24.tv/digitalizacja-dziel-magdaleny-abakanowicz-w-pawilonie-czterech-kopul/?fbclid=IwAR3yGEcggq4FLL0ONROYAs0HWs7ccwQhwDOwRwZHRKB-WVFXiUTT-ZLYiYQ" className="red">
                Echo24
              </a>
            </p>
          </TextBlock>
        </Col>
      </Row>
    </NewsWrapper>
  );
};

export default NewsOProcesieSkanowania;
