import React from "react";
import Container from "../../../../components/container/container";
import Col from "../../../../components/grid/col";
import Grid from "../../../../components/grid/grid";
import Row from "../../../../components/grid/row";
import TextBlock from "../../../../components/writing-tools/text-block/text-block";
import ColorSection from "../../../../components/color-section/color-section";

import ImgtextBlock from "../../../../components/img-text-block/img-text-block";
import "./team-pawilon-section.scss";
import JustynaImg from "../../../../assets/justynaoleksy.png";
import AgataImg from "../../../../assets/agataizykowska.png";
import IwonaImg from "../../../../assets/iwonagolaj.png";
import useWindowSize from "../../../../tools/useWindowSize";

interface Props {
  className?: string;
}

const TeamPawilonSection: React.FC<Props> = (props: Props) => {
  const { className } = props;
  const size = useWindowSize();

  const team = [
    <ImgtextBlock img={IwonaImg} alt="Iwona Gołaj" aosDelay={0} className="pt-md-5 pb-md-5 pt-3 pb-3">
      <p className="subtitle pt-md-3 pt-1">
        <b>Iwona Gołaj</b>
      </p>
      <p className="pb-md-0 pb-3">Kustosz w Muzeum Narodowym we Wrocławiu. Ukończyła kulturoznawstwo na Uniwersytecie Wrocławskim, obecnie prowadzi Dział Edukacji w Pawilonie Czterech Kopuł. Autorka i współautorka przewodników po wystawach stałych Muzeum Narodowego we Wrocławiu oraz po kolekcji sztuki polskiej II połowy XX i początku XXI wieku prezentowanej w Pawilonie. Jest pomysłodawczynią wielu projektów edukacyjnych (w tym: Nie tylko Pawilon. Muzea Sztuki Współczesnej na świecie; Kurs historii sztuki. Klasycy nowoczesności; Prawdziwa twarz mistrza. Artyści Pawilonu Czterech Kopuł).</p>
    </ImgtextBlock>,
    <ImgtextBlock img={JustynaImg} alt="Justyna Oleksy" aosDelay={0} className="pt-md-5 pb-md-5 pt-3 pb-3">
      <p className="subtitle pt-md-3 pt-1">
        <b>Justyna Oleksy</b>
      </p>
      <p className="pb-md-0 pb-3">Adiunktka w Dziale Edukacji Pawilonu Czterech Kopuł, twórczyni Teatru Czterech, który w duchu połączenia sztuk tworzy performatywne finisaże wystaw czasowych w Pawilonie. Absolwentka filologii polskiej (specjalizacja: sztuki widowiskowe) oraz dziennikarstwa i komunikacji społecznej (specjalizacja creative writing). Autorka cyklu warsztatów performatywnych „Czwarta ściana”. Zawodowo związana z edukacją muzealną oraz teatralną, prowadzi wykłady, oprowadzania oraz warsztaty dla różnych grup wiekowych z zakresu zagadnień związanych ze sztuką współczesną oraz ekspresją sceniczną. Prowokuje zachowania twórcze. Integruje w duchu kreatywności.</p>
    </ImgtextBlock>,
    <ImgtextBlock img={AgataImg} alt="Agata Iżykowska-Uszczyk" aosDelay={0} className="pt-mb-5 mt-mb-5 pt-3 mt-3 pb-md-3 pb-5">
      <p className="subtitle pt-md-3 pt-1">
        <b>Agata Iżykowska-Uszczyk</b>
      </p>
      <p className="pb-md-0 pb-3">Adiunktka w Dziale Edukacji Pawilonu Czterech Kopuł i koordynatorka dostępności w Muzeum Narodowym we Wrocławiu. Doktorantka Instytutu Kulturoznawstwa Uniwersytetu Wrocławskiego. Pedagożka, muzealniczka i animatorka kultury. Od 2015 roku realizuje oraz współorganizuje projekty włączające w życie kulturalne Wrocławia. Autorka cyklu warsztatów „Sztuka szuka zmysłów” oraz „Dotknij muzeum”, w którym otwiera na praktykę poznawania sztuki współczesnej wszystkimi zmysłami. Pełni funkcję koordynatorki do spraw dostępności w Muzeum Narodowym we Wrocławiu. </p>
    </ImgtextBlock>,
  ];
  return (
    <ColorSection className={`team-pawilon-section ${className ? className : ""}`} dataAos="fade-up" color="white">
      <Container>
        <Grid>
          <Row gap={96}>
            <Col size={[12, 12, 12, 12]}>
              <TextBlock>
                <h2 className="title mb-5" data-aos="fade-up">
                  Zespół Pawilonu
                  <br /> Czterech Kopuł
                </h2>
              </TextBlock>
            </Col>
            <Col size={[6, 6, 12, 12]}>
              {team[0]}
              {size.width <= 600 ? team[2] : null}
              {team[1]}
            </Col>
            {size.width > 600 ? (
              <Col size={[6, 6, 12, 12]} className="pt-mb-5 mt-mb-5 pt-3 mt-3">
                <div className="break">{team[2]}</div>
              </Col>
            ) : null}
          </Row>
        </Grid>
      </Container>
    </ColorSection>
  );
};

export default TeamPawilonSection;
