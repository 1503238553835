export const trackMousePosition = () => {
    // (window as any).currentMouseX = 0;
    // (window as any).currentMouseY = 0;

    // // Guess the initial mouse position approximately if possible:
    // var hoveredElement: any = document.querySelectorAll(':hover');
    // hoveredElement = hoveredElement[hoveredElement.length - 1]; // Get the most specific hovered element

    // if (hoveredElement != null) {
    //     var rect = hoveredElement.getBoundingClientRect();
    //     // Set the values from hovered element's position
    //     (window as any).currentMouseX = window.scrollX + rect.x;
    //     (window as any).currentMouseY = window.scrollY + rect.y;
    // }

    // Listen for mouse movements to set the correct values
    window.addEventListener('mousemove', function (e) {
        (window as any).mouse = {
            page: { x: e.pageX, y: e.pageY },
            client: { x: e.clientX, y: e.clientY },
            clientRelative: { x: ((e.clientX / window.innerWidth) - 0.5) * 2, y: ((e.clientY / window.innerHeight) - 0.5) * 2 }
        };
    }, /*useCapture=*/true);
}

