import React from "react";
import Container from "../../../../components/container/container";
import Col from "../../../../components/grid/col";
import Grid from "../../../../components/grid/grid";
import Row from "../../../../components/grid/row";
import TextBlock from "../../../../components/writing-tools/text-block/text-block";
import ColorSection from "../../../../components/color-section/color-section";
import FooterIconA from "./../../../../assets/footerIconA.svg";
import FooterIconB from "./../../../../assets/footerIconB.svg";
import FooterIconC from "./../../../../assets/footerIconC.svg";
import CentrumInnowacjiImg from "./../../../../assets/centrumInnowacji.svg";
import useTheme from "../../../../tools/useTheme";
import "./partners-section.scss";
import PartnerImg from "./../../../../assets/Slice_Mod_Logo.svg";

interface Props {
  className?: string;
}

const PartnersSection: React.FC<Props> = (props: Props) => {
  const { className } = props;
  const theme = useTheme();

  return (
    <ColorSection className={`partners-section ${className ? className : ""} ${theme}`} dataAos="fade-up" color="#FFAF21">
      <Container>
        <Grid>
          <Row>
            <Col size={[12, 12, 12, 12]}>
              <TextBlock className="pb-md-5 mb-md-5 pb-3 mb-3">
                <div className="title" data-aos="fade-up">
                  <h2>Partnerzy</h2>
                </div>
              </TextBlock>
            </Col>
          </Row>
          <Row>
            <Col size={[3, 3, 6, 12]} className="partner-col">
              <TextBlock aosDelay={100}>
                <div className="img img__fundacja" data-aos="slide-up" style={{ backgroundImage: `url('${FooterIconA}')` }} title="Fundacja Collect" />
                <p className="pt-3" data-aos="slide-up">
                  <strong>Fundacja Collect</strong>
                </p>
              </TextBlock>
            </Col>
            <Col size={[3, 3, 6, 12]} className="partner-col">
              <TextBlock aosDelay={200}>
                <div className="img" data-aos="slide-up" style={{ backgroundImage: `url('${FooterIconB}')` }} title="Muzeum Narodowe we Wrocławiu" />
                <p className="pt-3" data-aos="slide-up">
                  <strong>
                    Muzeum Narodowe <br /> we Wrocławiu
                  </strong>
                </p>
              </TextBlock>
            </Col>
            <Col size={[3, 3, 6, 12]} className="partner-col">
              <TextBlock aosDelay={300}>
                <div className="img" data-aos="slide-up" style={{ backgroundImage: `url('${FooterIconC}')` }} title="Ministerstwo Kultury Dziedzictwa Narodowego i Sportu" />
                <p className="pt-3" data-aos="slide-up">
                  <strong>
                    Ministerstwo Kultury Dziedzictwa Narodowego <br /> i Sportu
                  </strong>
                </p>
              </TextBlock>
            </Col>
            <Col size={[3, 3, 6, 12]} className="partner-col">
              <TextBlock aosDelay={400}>
                <div className="img" data-aos="slide-up" style={{ backgroundImage: `url('${CentrumInnowacjiImg}')` }} title="Przejście Centrum Innowacji" />
                <p className="pt-2" data-aos="slide-up">
                  <strong>Przejście Centrum Innowacji</strong>
                </p>
              </TextBlock>
            </Col>
            <Col size={[3, 3, 6, 12]} className="partner-col">
              <TextBlock aosDelay={400}>
                <div className="img" data-aos="slide-up" style={{ backgroundImage: `url('${PartnerImg}')` }} title="Slice Mod" />
                <p className="pt-0" data-aos="slide-up">
                  <strong>Slice Mod</strong>
                </p>
              </TextBlock>
            </Col>
          </Row>
        </Grid>
      </Container>
    </ColorSection>
  );
};

export default PartnersSection;
