import "./App.scss";
import React, { useEffect } from "react";
import { RoutePath } from "./route-paths";
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from "react-toasts";
import SlideOverlay from "./components/slide-overlay/slide-overlay";
import HomePage from "./pages/home-page/home-page";
import CookiePolicyPopup from "./components/cookie-policy-popup/cookie-policy-popup";
import AboutPage from "./pages/about-page/about-page";
import Navbar from "./components/navbar/navbar";

import { Switch, Route, useLocation } from "react-router-dom";
import ReduxCallbacks from "./redux/callback-store";
import AOS from "aos";
import "aos/dist/aos.css";
import useTheme from "./tools/useTheme";
import useSelect from "./tools/useSelect";
import ModelsPage from "./pages/models-page/models-page";
import ModelPage from "./pages/model-page/model-page";
import WarsztatyPage from "./pages/warsztaty-page/warsztaty-page";
import AktualnosciPage from "./pages/aktualnosci-page/aktualnosci-page";
import ReduxBg from "./redux/bg-store";
import MultimediaPage from "./pages/multimedia-page/multimedia-page";
import Popup from "./components/popup/popup";
import AplikacjaARPage from "./pages/aplikacja-ar/aplikacja-ar-page";
import SciezkiPage from "./pages/sciezki-page/sciezki-page";

const App: React.FC = () => {
  const theme = useTheme();
  const isNavbarOpenActive = useSelect(ReduxCallbacks.prefix, "is_navbarOpenActive");
  const bgColor = useSelect(ReduxBg.prefix, "bgColor");

  const location = useLocation();

  const initAos = () => {
    setTimeout(() => {
      AOS.init({
        duration: 2000,
        disable: function () {
          var maxWidth = 900;
          return window.innerWidth < maxWidth;
        },
      });
      window.scrollTo(0, 0);
    }, 200);
  };

  useEffect(() => {
    initAos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initAos();
  }, [location]);

  return (
    <div className={`theme-${theme}`}>
      <div className="overflow-wrapper">
        <SlideOverlay />
        <Navbar />
        <Popup />
        <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT} />
        <CookiePolicyPopup />
      </div>

      <div className={`overlay${isNavbarOpenActive ? " isActive" : ""}`}></div>
      <div style={{ backgroundColor: bgColor.hex, transitionDuration: bgColor.speed + "ms" }}>
        <Switch>
          <Route exact path={RoutePath.ABOUT}>
            <div className="overflow-wrapper">
              <AboutPage />
            </div>
          </Route>
          <Route path={RoutePath.MODEL}>
            <div className="overflow-wrapper">
              <ModelPage />
            </div>
          </Route>
          <Route path={RoutePath.MODELS}>
            <div className="overflow-wrapper">
              <ModelsPage />
            </div>
          </Route>
          <Route path={RoutePath.WARSZTATY}>
            <div className="overflow-wrapper">
              <WarsztatyPage />
            </div>
          </Route>
          <Route path={RoutePath.NEWS}>
            <div className="overflow-wrapper">
              <AktualnosciPage />
            </div>
          </Route>
          <Route path={RoutePath.MULTIMEDIA}>
            <div className="overflow-wrapper">
              <MultimediaPage />
            </div>
          </Route>
          <Route path={RoutePath.AR}>
            <div className="overflow-wrapper">
              <AplikacjaARPage />
            </div>
          </Route>
          <Route path={RoutePath.SCIEZKI}>
            <div className="overflow-wrapper">
              <SciezkiPage />
            </div>
          </Route>
          <Route path={RoutePath.HOME}>
            <HomePage />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default App;
