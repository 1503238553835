import { useEffect, useState } from "react";

export default function useScroll(ref?: any) {
  const [scroll, setScroll] = useState(getScroll);

  function getRefVisability() {
    if (ref && ref.current) {
      const refSize = ref.current.getBoundingClientRect();
      const visibilityRatio = (-refSize.top / refSize.height);
      return Number((visibilityRatio > 1 ? 1 : visibilityRatio < -1 ? -1 : visibilityRatio).toFixed(10));
    } else return undefined;
  }

  function getScroll() {
    return {
      windowScrollY: window.scrollY,
      refVisibility: getRefVisability(),
    };
  }

  const handleScroll = () => {
    setScroll(getScroll);
  }

  useEffect(() => {
    window.addEventListener("touchmove", handleScroll);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("touchmove", handleScroll);
      window.removeEventListener("scroll", handleScroll);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   handleScroll();
  //   setTimeout(() => {
  //     handleScroll();
  //   }, 300);
  //   setTimeout(() => {
  //     handleScroll();
  //   }, 1000);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [slideOverlayState]) // detect change page
  return scroll
}