import { useEffect, useState } from "react";
import { globalStore } from "./../index";

export default function useSelect(prefix: any, property?: any) {
  const [value, setValue] = useState(property ? globalStore.GetPartnerState(prefix)[property] : undefined);

  const localStateChanged = (localStateChanged: any) => {
    if (property) setValue(localStateChanged[property])
    else setValue(localStateChanged)
  };

  useEffect(() => {
    globalStore.Subscribe(prefix, localStateChanged);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return value;
}