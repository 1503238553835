import React, { useEffect, useState } from "react";
import NewsTile from "./news-tile";
import "./news-swiper.scss";

import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import SwiperCore, { Navigation, Pagination, Scrollbar, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { RoutePath } from "../../../../../route-paths";
import useTheme from "../../../../../tools/useTheme";
import useWindowSize from "../../../../../tools/useWindowSize";
import { generatePath, useParams } from "react-router-dom";
import news, { NewsDto } from "../../../../../data/news/news";
import { a11yOptions } from "../../../../../tools/a11yOptions";

SwiperCore.use([Navigation, Pagination, Scrollbar, Autoplay]);

const NewsSwiper: React.FC = () => {
  const size = useWindowSize();
  const theme = useTheme();

  const { newsId }: { newsId: string } = useParams();
  const [newsItem, setNewsItem] = useState<NewsDto | undefined>(undefined);

  useEffect(() => {
    const newsData = news.find((x) => x.url === newsId);
    setNewsItem(newsData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsId]);

  return (
    <div className={`other-news-swiper`} data-aos="slide-up">
      <div className={`news-wrapper ${theme}`}>
        <div className="slider-nav">
          <div className="swiper-button-prev" data-aos="slide-right">
            &lt;
          </div>
          <div className="swiper-button-next" data-aos="slide-left">
            &gt;
          </div>
        </div>
        <div id="livearea-other-news" aria-live="polite"></div>
        <Swiper
          spaceBetween={size.isMobile ? 64 : 96}
          slidesPerView={size.isMobile ? 1 : 3}
          loop={true}
          navigation={{
            prevEl: ".swiper-button-prev",
            nextEl: ".swiper-button-next",
          }}
          autoplay={false}
          keyboard={true}
          a11y={a11yOptions}
          onSlideChange={(swiper) => {
            var myslide = swiper.realIndex;
            var swiperLive = document.getElementById("livearea-other-news");
            if (swiperLive) {
              swiperLive.ariaLabel = `Wyświetlony slajd: ${news[myslide].title}`;
            }
          }}
        >
          {news
            .filter((x) => x.url !== newsItem?.url)
            .map((item: NewsDto, index: number) => {
              return (
                <SwiperSlide key={index}>
                  <NewsTile alt={item.title} date={item.date} img={item.previewImg} offset={200 * index} to={generatePath(RoutePath.NEWS, { newsId: item.url })}>
                    {item.title}
                  </NewsTile>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
    </div>
  );
};

export default NewsSwiper;
