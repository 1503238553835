import React from "react";
import ColorSection from "../../../../components/color-section/color-section";
import Container from "../../../../components/container/container";
import Col from "../../../../components/grid/col";
import Grid from "../../../../components/grid/grid";
import Row from "../../../../components/grid/row";
import "./other-news-section.scss";
import NewsSwiper from "./news-swiper/news-swiper";
import TextBlock from "../../../../components/writing-tools/text-block/text-block";

interface Props {
  className?: string;
}

const OtherNewsSection: React.FC<Props> = (props: Props) => {
  const { className } = props;

  return (
    <ColorSection className={`other-news-section ${className ? className : ""}`} color="white">
      <Container>
        <TextBlock>
          <div className="title" data-aos="fade-up">
            <h2>Pozostałe aktualności</h2>
          </div>
        </TextBlock>
        <Grid>
          <Row gap={0} className="other-news-section__content">
            <Col size={[12, 12, 12]}>
              <NewsSwiper />
            </Col>
          </Row>
        </Grid>
      </Container>
    </ColorSection>
  );
};

export default OtherNewsSection;
