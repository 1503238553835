import { Store } from "redux";
import { globalStore } from "../index";

var initialState = {
  slideOverlay: { isActive: false, redirectTo: "" },
  is_navbarOpenActive: false,
}

class Actions {
  static hideSlideOverlay = () => {
    globalStore.DispatchAction(
      ReduxCallbacks.prefix,
      {
        type: ReduxCallbacks.prefix + "hideSlideOverlay",
        payload: null,
      }
    )
  }
  static showSlideOverlay = (redirectTo: string) => {
    globalStore.DispatchAction(
      ReduxCallbacks.prefix,
      {
        type: ReduxCallbacks.prefix + "showSlideOverlay",
        payload: redirectTo,
      }
    )
  }
  static setNavbarOpen = (isOpen: boolean) => {
    globalStore.DispatchAction(
      ReduxCallbacks.prefix,
      {
        type: ReduxCallbacks.prefix + "setNavbarOpen",
        payload: isOpen,
      }
    )
  }
}

class Reducer {
  static Register = (state = initialState, action: any) => {
    if (action.type === ReduxCallbacks.prefix + "hideSlideOverlay")
      return { ...state, slideOverlay: { isActive: false, redirectTo: "" } };
    if (action.type === ReduxCallbacks.prefix + "showSlideOverlay")
      return { ...state, slideOverlay: { isActive: true, redirectTo: action.payload } };
    if (action.type === ReduxCallbacks.prefix + "setNavbarOpen")
      return { ...state, is_navbarOpenActive: action.payload };
    return state;
  }
}

class Init {
  static Register = () => {
    ReduxCallbacks.localStore = globalStore.CreateStore(ReduxCallbacks.prefix, Reducer.Register, []);

    globalStore.RegisterGlobalActions(ReduxCallbacks.prefix, [
      ReduxCallbacks.prefix + "hideSlideOverlay",
      ReduxCallbacks.prefix + "showSlideOverlay",
      ReduxCallbacks.prefix + "setNavbarOpen",

    ]);
  }
}

class ReduxCallbacks {
  static prefix = "ReduxCallbacks_";
  static localStore: Store<any, any>;
  Init = Init;
  static Actions = Actions;
}

export default ReduxCallbacks;