import { useEffect, useState } from "react";
import "./lang-btn.scss";
import LangImg from "./lang-icon.svg";

const LangBtn = () => {
  const [showTranslate, setShowTranslate] = useState(false);
  useEffect(() => {
    // in some cases, the google translate script adds a style to the opening html tag.
    // this added style disables scrolling.
    // the next 3 lines removes this added style in order to re-enable scrolling.
    if ((window as any).document.scrollingElement.hasAttribute("style")) {
      (window as any).document.scrollingElement.setAttribute("style", "");
    }
  });

  const handleClick = () => {
    // var addScript = document.createElement('script');
    // addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
    // document.body.appendChild(addScript);
    setShowTranslate((x) => true);
  };

  useEffect(() => {
    (window as any).setGoogleTranslate();
  }, []);

  useEffect(() => {
    if (showTranslate) {
      (window as any).googleTranslate();
    } else {
      //   (document as any).getElementById("google_translate_element").innerHTML = "";
      //   (window as any).clearGoogleTranslate();
    }
  }, [showTranslate]);

  return (
    <>
      {showTranslate ? (
        <>
          {/* <ReactHelmet>
            {html(`
            <script type="text/javascript">
            function googleTranslateElementInit() {
                new google.translate.TranslateElement(
                    {
                        pageLanguage: "pl",
                        includedLanguages: 'en',
                    layout: window.google.translate.TranslateElement.InlineLayout.VERTICAL,
                    autoDisplay: true
                }, 
                'google_translate_element'
                );
            }
            window.googleTranslateElementInit = googleTranslateElementInit;
            </script>
        `)}
          </ReactHelmet> */}
        </>
      ) : null}
      <div className={`lang-btn px-1 mr-4`} onClick={() => handleClick()}>
        <div id="google_translate_element"></div>

        {showTranslate ? null : (
          <>
            &nbsp;
            <img className="lang-icon" src={LangImg} alt="Change language" />
          </>
        )}
      </div>
    </>
  );
};

export default LangBtn;
