import React from "react";
import ColorSection from "../../../../components/color-section/color-section";
import Container from "../../../../components/container/container";
import Col from "../../../../components/grid/col";
import Grid from "../../../../components/grid/grid";
import Row from "../../../../components/grid/row";
import TopLink from "../../../../components/top-link/top-link";
import TextBlock from "../../../../components/writing-tools/text-block/text-block";
import { RoutePath } from "../../../../route-paths";
import AbakanLargeImg from "./../../../../assets/ar-phone.png";
import "./ar-section.scss";

interface Props {
  className?: string;
}

const ArSection: React.FC<Props> = (props: Props) => {
  const { className } = props;

  return (
    <ColorSection className={`ar-section ${className ? className : ""}`} dataAos="slide-up" color="white">
      <Container>
        <Grid>
          <Row gap={0} className="ar-section__content">
            <Col size={[5, 6, 6, 12]} offset={[1, 0, 0, 0]} className="content-col">
              <TextBlock>
                <div className="quote" data-aos="slide-down">
                  <h2>
                    Augmented <br />
                    reality [AR]
                  </h2>
                </div>
                <div className="pl-xl-4 pt-xl-4 pl-lg-0 pt-lg-0" data-aos="fade-up">
                  <p>Pobierz specjalnie przygotowaną przez nas aplikację rozszerzonej rzeczywistości i przy pomocy telefonu lub tableta zapoznaj się z dziełami Magdaleny Abakanowicz w dowolnie wybranym przez siebie miejscu!</p>
                  <div className="d-flex">
                    <TopLink className="more-link" to={RoutePath.AR}>
                      Sprawdź
                    </TopLink>
                  </div>
                </div>
              </TextBlock>
            </Col>
            <Col size={[5, 5, 5, 8]} offset={[1, 1, 1, 4]} className="image-col" data-aos="fade-up">
              <img src={AbakanLargeImg} alt="Mutant wystający z telefonu" data-aos="fade-up" />
            </Col>
          </Row>
        </Grid>
      </Container>
    </ColorSection>
  );
};

export default ArSection;
