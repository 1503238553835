import React from "react";
import OutsideWrapperDetector from "../outside-wrapper-detector/outside-wrapper-detector.component";
import ReduxPopup from "../../redux/popup-store";
import useSelect from "../../tools/useSelect";
import CloseIcon from "./../../assets/cancel_icon.svg";
import "./popup.scss";

interface Props {
  children?: React.ReactNode;
  className?: string;
}

const Popup: React.FC<Props> = (props: Props) => {
  const { className = "" } = props;
  const popupData = useSelect(ReduxPopup.prefix, "popupData");

  const closePopup = () => {
    ReduxPopup.Actions.setPopupData(false, undefined);
  };
  
  return (
    <>
      {popupData?.isOpen ? (
        <div className={`popup-wrapper size-${popupData.size} ${className}`}>
          <OutsideWrapperDetector handleClose={closePopup}>
            <div className="popup">
              <img className="close-icon" src={CloseIcon} alt="Zamknij" onClick={closePopup} />
              {popupData?.children}
            </div>
          </OutsideWrapperDetector>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Popup;