import React from "react";
import Container from "../../../../components/container/container";
import Col from "../../../../components/grid/col";
import Grid from "../../../../components/grid/grid";
import Row from "../../../../components/grid/row";
import TopLink from "../../../../components/top-link/top-link";
import TextBlock from "../../../../components/writing-tools/text-block/text-block";
import { RoutePath } from "../../../../route-paths";
import "./edu-paths-section.scss";
import AbakanowiczCircleImg from "./../../../../assets/abakanowicz-circle.png";
import AbakanMinImg from "./../../../../assets/abakan-min.png";
import ColorSection from "../../../../components/color-section/color-section";
import HoverParallax from "../../../../components/hover-parallax/hover-parallax";
import useWindowSize from "../../../../tools/useWindowSize";

interface Props {
  className?: string;
}

const EduPathsSection: React.FC<Props> = (props: Props) => {
  const { className } = props;
  const size = useWindowSize();

  return (
    <ColorSection className={`edu-paths-section ${className ? className : ""}`} dataAos="zoom-in" color="white">
      <Container>
        <Grid>
          <Row gap={0} className="articles-section__content">
            {size.width <= 900 ? (
              <Col size={[5, 5, 5, 12]} className="image-col">
                <HoverParallax fitContent={true}>
                  <img src={AbakanowiczCircleImg} alt="Zdjęcie Abakanowicz w kole" data-aos="slide-left" />
                </HoverParallax>
              </Col>
            ) : null}
            <Col size={[5, 5, 5, 10]} offset={[1, 1, 1, 1]} className="content-col">
              <TextBlock>
                <HoverParallax fitContent={true}>
                  <h2 className="quote" data-aos="slide-up">
                    Nie lubię <br /> reguł.
                    <img src={AbakanMinImg} alt="(Ikona Abakan)" data-aos="slide-left" />
                    <br />
                    <b>Są&nbsp;wrogami wyobraźni.</b>
                  </h2>
                  <TopLink to={RoutePath.SCIEZKI} className="more-link">
                    Ścieżki edukacyjne &gt;
                  </TopLink>
                </HoverParallax>
              </TextBlock>
            </Col>
            {size.width > 900 ? (
              <Col size={[5, 5, 5, 12]} className="image-col">
                <HoverParallax fitContent={true}>
                  <img src={AbakanowiczCircleImg} alt="Zdjęcie Abakanowicz w kole" data-aos="slide-left" />
                </HoverParallax>
              </Col>
            ) : null}
          </Row>
        </Grid>
      </Container>
    </ColorSection>
  );
};

export default EduPathsSection;
