import React from "react";
import "./contrast-btn.scss";
import ContrastImg from "./../../assets/contrast.svg";
import useTheme from "../../tools/useTheme";

const ContrastBtn: React.FC = () => {
  const theme = useTheme();

  return (
    <button className="contrast-btn" tabIndex={2} title={`${theme === "dark" ? "Wyłącz" : "Włącz"} widok kontrastowy`}>
      <img src={ContrastImg} alt={`${theme === "dark" ? "Wyłącz" : "Włącz"} widok kontrastowy`} />
    </button>
  );
};

export default ContrastBtn;
