/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import Container from "../../components/container/container";
import Col from "../../components/grid/col";
import Grid from "../../components/grid/grid";
import Row from "../../components/grid/row";
import useTheme from "../../tools/useTheme";
import "./footer.scss";

const Footer: React.FC = () => {
  const theme = useTheme();

  return (
    <footer className={theme}>
      <Container>
        <Grid>
          <Row>
            <Col size={6} className="company-col">
              <p>Copyright © Fundacja Collect 2021</p>
            </Col>
            <Col size={6} className="creator-col">
              <p>
                Realizacja:{" "}
                <a href="https://spnm.pl" target="_blank">
                  SPNM
                </a>{" "}
                &amp;{" "}
                <a href="https://codeebo.pl" target="_blank">
                  Codeebo
                </a>
              </p>
            </Col>
          </Row>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;
