import React from "react";
import ColorSection from "../../../../components/color-section/color-section";
import Container from "../../../../components/container/container";
import Col from "../../../../components/grid/col";
import Grid from "../../../../components/grid/grid";
import Row from "../../../../components/grid/row";
import TitleBlock from "../../../../components/title-block/title-block";
import "./news-section.scss";
import NewsSwiper from "./news-swiper/news-swiper";

interface Props {
  className?: string;
}

const NewsSection: React.FC<Props> = (props: Props) => {
  const { className } = props;

  return (
    <ColorSection className={`news-section ${className ? className : ""}`} color="#FFAF21">
      <Container>
        <TitleBlock>Aktualności</TitleBlock>
        <Grid>
          <Row gap={0} className="news-section__content">
            <Col size={[12, 12, 12]}>
              <NewsSwiper />
            </Col>
          </Row>
        </Grid>
      </Container>
    </ColorSection>
  );
};

export default NewsSection;
