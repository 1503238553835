import React from "react";
import Col from "../../../components/grid/col";
import Row from "../../../components/grid/row";
import TextBlock from "../../../components/writing-tools/text-block/text-block";
import Prefab from "../../../pages/model-page/sections/model-section/prefab";
import ModelWrapper from "../model-wrapper";
import DreamAudio from "./medrcy.ogg";

interface Props {
  className?: string;
}

const ModelMedrcy: React.FC<Props> = (props: Props) => {
  const { className } = props;

  return (
    <ModelWrapper className={className}>
      <Row>
        <Col size={[12, 12, 12, 12]}>
          <Prefab src="https://sketchfab.com/models/19eb2e33b19940339183593e10d42238/embed" />
          <TextBlock className="pt-3">
            <h1 className="subtitle pb-4 thin">
              <b>Mędrcy </b>&nbsp;1987-88
            </h1>
          </TextBlock>
        </Col>
      </Row>
      <Row>
        <Col size={[6, 6, 12, 12]} className="pt-3">
          <Row>
            <Col size={[8, 8, 12, 12]}>
              <TextBlock>
                <p>
                  {" "}
                  2 figury z cyklu “Mędrcy” stworzone przez Magdalenę
                  Abakanowicz w latach 1987-88
                </p>
                <h2 className="pt-3">
                  <b>Wymiary figury:</b>
                </h2>
                <p>
                  wysokość - 112 cm <br />
                  szerokość - 55 cm <br />
                  głębokość - 67 cm
                </p>
              </TextBlock>
            </Col>
            <Col size={[4, 4, 12, 12]}>
              <TextBlock>
                <h2 className="pt-3">
                  <b>Materiał:</b>
                </h2>
                <p>tkanina jutowa utwardzana żywicą, postumenty metalowe</p>
              </TextBlock>
            </Col>
          </Row>
          <Row>
            <Col size={[12, 12, 12, 12]} className="pt-3">
              <TextBlock>
                <p>
                  Dzieło ze zbiorów Muzeum Narodowego we Wrocławiu prezentowane
                  na wystawie stałej w Pawilonie Czterech Kopuł. Dzieło zostało
                  przekazane przez artystkę muzeum w 1990 roku.
                </p>
              </TextBlock>
            </Col>
          </Row>
        </Col>
        <Col size={[6, 6, 12, 12]} className="pt-3">
          <TextBlock>
            <p className="title-audio">
              <b>Posłuchaj opisu:</b>
              <audio
                autoPlay={false}
                controls
                controlsList="nofullscreen nodownload noremoteplayback noplaybackrate"
              >
                <source src={DreamAudio} type="audio/ogg" />
                Your browser does not support the audio tag.
              </audio>
            </p>
            <p>
              2 Figury z cyklu Mędrcy autorstwa Magdaleny Abakanowicz zostały
              wykonane z tkaniny jutowej utwardzonej żywicą. Są to siedzące
              skorupy ludzkie bez głów z dłońmi wtopionymi w uda. Jedna z
              tytułowych form usadowiona jest na postumencie z litego metalu,
              druga siedzi na stelażu z metalowych rur stanowiących krawędzie
              pustego w środku prostopadłościanu. Oba postumenty mają takie same
              wymiary. Figury Mędrców wykonane są z jednego odlewu, w którym
              artystka umieszczała fragmenty tkaniny pozyskiwanej z używanych
              worków jutowych. Wcześniej płótno workowe moczyła w kleju
              stolarskim, następnie je w tym gipsowym odlewie układała i
              dociskała, a na końcu utwardzane żywicą syntetyczną.
              Monochromatyczne figury-skorupy, akcentujące właściwości materiału
              z którego powstały opowiadają o względności pojęcia mędrzec.
            </p>
          </TextBlock>
        </Col>
      </Row>
    </ModelWrapper>
  );
};

export default ModelMedrcy;
