import React from "react";
import Col from "../../../components/grid/col";
import Row from "../../../components/grid/row";
import TextBlock from "../../../components/writing-tools/text-block/text-block";
import Prefab from "../../../pages/model-page/sections/model-section/prefab";
import ModelWrapper from "../model-wrapper";
import DreamAudio from "./dream.ogg";

interface Props {
  className?: string;
}

const ModelDream: React.FC<Props> = (props: Props) => {
  const { className } = props;

  return (
    <ModelWrapper className={className}>
      <Row>
        <Col size={[12, 12, 12, 12]}>
          <Prefab src="https://sketchfab.com/models/b2801009cb1d4d53823cf21b329b627b/embed" />
          <TextBlock className="pt-3">
            <h1 className="subtitle pb-4 thin">
              <b>Dream&nbsp;II,</b>&nbsp;2008
            </h1>
          </TextBlock>
        </Col>
      </Row>
      <Row>
        <Col size={[6, 6, 12, 12]} className="pt-3">
          <Row>
            <Col size={[8, 8, 12, 12]}>
              <TextBlock>
                <p>„Dream II” stworzony przez Magdalenę Abakanowicz w 2008 roku.</p>
                <h2 className="pt-3">
                  <b>Wymiary figury:</b>
                </h2>
                <p>
                  wysokość - 184 cm <br />
                  szerokość - 65 cm <br />
                  głębokość - 65 cm
                </p>
              </TextBlock>
            </Col>
            <Col size={[4, 4, 12, 12]}>
              <TextBlock>
                <h2 className="pt-3">
                  <b>Materiał:</b>
                </h2>
                <p>tkanina jutowa, żywica.</p>
              </TextBlock>
            </Col>
          </Row>
          <Row>
            <Col size={[12, 12, 12, 12]} className="pt-3">
              <TextBlock>
                <p>Dzieło ze zbiorów Muzeum Narodowego we Wrocławiu prezentowane na wystawie stałej w Pawilonie Czterech Kopuł.</p>
              </TextBlock>
            </Col>
          </Row>
        </Col>
        <Col size={[6, 6, 12, 12]} className="pt-3">
          <TextBlock>
            <p className="title-audio">
              <b>Posłuchaj opisu:</b>
              <audio autoPlay={false} controls controlsList="nofullscreen nodownload noremoteplayback noplaybackrate">
                <source src={DreamAudio} type="audio/ogg" />
                Your browser does not support the audio tag.
              </audio>
            </p>
            <p>
              „Dream II” to rzeźba wykonana z tkaniny jutowej utwardzonej żywicą. Rzeźba przedstawia postać stojącą na baczność, wysokości 184 cm. Szerokość i głębokość to 65 centymetrów. Patrząc od góry, widzimy, że w miejscu głowy Abakanowicz zamieściła trójkąt ułożony na płasko względem sufitu. Wierzchołek trójkąta równoramiennego skierowany jest w stronę odbiorcy stojącego na wprost rzeźby. Kształt przywodzi na myśl maskę założoną na głowę człowieka, bowiem z geometrycznej głowy wychodzi szyja, która łączy się z pozostałą częścią sylwetki. Ręce postaci mocno przylegają do ciała, nogi rozstawione są w lekkim rozkroku. W połowie korpusu, na wysokości pępka, pojawia się odstający od ciała fragment przypominający kształtem powiewającą tkaninę. „Dream II” składa się wyłącznie ze skorupy przedstawiającej odcisk przodu ludzkiej sylwetki. Cały tył jest pusty i wydrążony.
              <br />
              <br />
              „Dream II” to praca, która stanowi punkt wyjścia do przeprowadzenia działań dla starszych i młodszych odbiorców spotkań edukacyjnych. Wychodząc od pytania o relację człowieka i natury lub człowieka i zwierzęcia, możemy stworzyć bogatą historię o wrażliwości na otaczający nas świat. Koncentrując się również na motywie natury, wskazujemy na jego unikatowe wprowadzenie do świata sztuki przez Magdalenę Abakanowicz.
            </p>
          </TextBlock>
        </Col>
      </Row>
    </ModelWrapper>
  );
};

export default ModelDream;
