import React from "react";
import Container from "../../../../components/container/container";
import Col from "../../../../components/grid/col";
import Grid from "../../../../components/grid/grid";
import Row from "../../../../components/grid/row";
import TextBlock from "../../../../components/writing-tools/text-block/text-block";
import "./model-section.scss";
import ColorSection from "../../../../components/color-section/color-section";
import TopLink from "../../../../components/top-link/top-link";
import { RoutePath } from "../../../../route-paths";
import { useParams } from "react-router-dom";

interface Props {
  className?: string;
}

const NoModelResult: React.FC<Props> = (props: Props) => {
  const { className } = props;
  const { modelId }: { modelId: string } = useParams();

  return (
    <ColorSection className={`model-section ${className ? className : ""}`} dataAos="fade-up" color="white">
      <Container>
        <Grid>
          <Row>
            <Col size={[12, 12, 12, 12]}>
              <TextBlock className="pb-5">
                <div>
                  <TopLink to={RoutePath.MODELS} className="more-link">
                    &lt; Powrót do modeli 3D
                  </TopLink>
                </div>
              </TextBlock>
            </Col>
          </Row>
          <Row>
            <Col size={[6, 6, 12, 12]} className="pt-3 text-center">
              <TextBlock>
                <p className="subtitle pb-4">Błędny adres strony, podany w linku model &nbsp;<i>&ldquo;{modelId}&rdquo;</i>&nbsp; nie istnieje w naszej bazie.</p>
              </TextBlock>
            </Col>
          </Row>
        </Grid>
      </Container>
    </ColorSection>
  );
};

export default NoModelResult;
