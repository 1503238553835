import React from "react";
import Col from "../grid/col";
import { RoutePath } from "../../route-paths";
import TopLink from "../top-link/top-link";
import Row from "../grid/row";
import Grid from "../grid/grid";
import { generatePath } from "react-router-dom";
import "./navbar.scss";
import news from "../../data/news/news";
import useWindowSize from "../../tools/useWindowSize";

interface Props {
  onClick: any;
  isOpen?: boolean;
}

const SubNavbar: React.FC<Props> = (props: Props) => {
  const menuItems = [
    {
      route: RoutePath.ABOUT,
      name: <>O&nbsp;projekcie</>,
      info: null, // "soon",
    },
    {
      route: RoutePath.SCIEZKI,
      name: <>Ścieżki&nbsp;edukacyjne</>,
      info: null, // "soon",
    },
    {
      route: RoutePath.MODELS,
      name: <>Modele&nbsp;3D</>,
      info: null, // "soon",
    },
    {
      route: RoutePath.WARSZTATY,
      name: <>Warsztaty</>,
      info: null, // "soon",
    },
    {
      route: generatePath(RoutePath.NEWS, { newsId: news[0].url }),
      name: <>Aktualności</>,
      info: null, // "soon",
    },
    {
      route: RoutePath.MULTIMEDIA,
      name: <>Multimedia</>,
      info: null, // "soon",
    },
    {
      route: RoutePath.AR,
      name: <>Augmented&nbsp;Reality</>,
      info: null, // "soon",
    },
  ];

  const windowSize = useWindowSize();

  return (
    <>
      <div className="subnavbar pt-5">
        {props.isOpen ? (
          <nav title={props.isOpen ? "Otwarte menu" : "Zamknięte menu"}>
            <Grid>
              {menuItems.map((x, index) => {
                return (
                  <div key={index} className="aos-subnavbar-link" data-aos={windowSize.isMobile ? null : "fade-down"} data-aos-delay={index * 100}>
                    <Row gap={16} key={index}>
                      <Col size={[2, 2, 2, 2]} className="info">
                        {x.info === "soon" ? "Wkrótce" : ""}
                      </Col>
                      <Col size={[10, 10, 10, 10]}>
                        <TopLink to={x.route} className={`${x.info === "soon" ? "disabled" : ""}`}>
                          {x.name}
                        </TopLink>
                      </Col>
                    </Row>
                  </div>
                );
              })}

              <Row gap={16} className="mt-5 pt-4 social-row">
                <Col size={[2, 2, 2, 2]} className="info"></Col>
                <Col size={[10, 10, 10, 10]}>
                  <div data-aos={"fade-up"} data-aos-delay={menuItems.length * 100 + 100}>
                    <a href="https://www.instagram.com/abakanowicz3d" className="mr-5" target="_blank" rel="noreferrer">
                      Instagram
                    </a>
                    <a href="https://www.facebook.com/Abakanowicz3D" target="_blank" rel="noreferrer">
                      Facebook
                    </a>
                  </div>
                </Col>
              </Row>
            </Grid>
          </nav>
        ) : null}
      </div>
    </>
  );
};

export default SubNavbar;
