import React from "react";
import AImg from "../../../../../assets/multimedia/DreamDigitalizacja/1.jpg";
import BImg from "../../../../../assets/multimedia/DreamDigitalizacja/2.jpg";
import CImg from "../../../../../assets/multimedia/DreamDigitalizacja/3.jpg";
import DImg from "../../../../../assets/multimedia/DreamDigitalizacja/4.jpg";
import EImg from "../../../../../assets/multimedia/DreamDigitalizacja/5.jpg";
import FImg from "../../../../../assets/multimedia/DreamDigitalizacja/6.jpg";
import ImageGallery from 'react-image-gallery';
interface Props {}

const DreamDigitalizacja: React.FC<Props> = (props: Props) => {
  const images = [
    {
      img: AImg,
      alt: "DreamDigitalizacja",
    },
    {
      img: BImg,
      alt: "DreamDigitalizacja 2",
    },
    {
      img: CImg,
      alt: "DreamDigitalizacja 3",
    },
    {
      img: DImg,
      alt: "DreamDigitalizacja 4",
    },
    {
      img: EImg,
      alt: "DreamDigitalizacja 5",
    },
    {
      img: FImg,
      alt: "DreamDigitalizacja 6",
    },
  ];
  
  return <ImageGallery items={[...images.map((x) => { return { original: x.img } })]} />;
};

export default DreamDigitalizacja;
