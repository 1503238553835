export class RoutePath {
  [key: string]: any;
  static RULES: string = '/warunki-uzytkowania';
  static ABOUT: string = '/o-projekcie';
  static WARSZTATY: string = '/warsztaty';
  static NEWS: string = '/aktualnosci/:newsId';
  static MULTIMEDIA: string = '/multimedia';
  static MODELS: string = '/modele-3d';
  static MODEL: string = '/modele-3d/:modelId';
  static AR: string = '/aplikacja-ar';
  static SCIEZKI: string = '/sciezki-edukacyjne';
  static HOME: string = '/';
}