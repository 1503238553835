import React from "react";
import Container from "../../../../components/container/container";
import Col from "../../../../components/grid/col";
import Grid from "../../../../components/grid/grid";
import Row from "../../../../components/grid/row";
import TextBlock from "../../../../components/writing-tools/text-block/text-block";
import ColorSection from "../../../../components/color-section/color-section";
import MiastoSpotkanImg from "./../../../../assets/prezydent.svg";
import DolnySlaskImg from "./../../../../assets/dolny_slask.svg";
import useTheme from "../../../../tools/useTheme";
import "./patroni-section.scss";

interface Props {
  className?: string;
}

const PatroniSection: React.FC<Props> = (props: Props) => {
  const { className } = props;
  const theme = useTheme();

  return (
    <ColorSection className={`patroni-section ${className ? className : ""} ${theme}`} dataAos="fade-up" color="#FFAF21">
      <Container>
        <Grid>
          <Row>
            <Col size={[12, 12, 12, 12]}>
              <TextBlock className="pb-md-5 mb-md-5 pb-3 mb-3">
                <h2 className="title" data-aos="fade-up">
                  Patroni honorowi
                </h2>
              </TextBlock>
            </Col>
          </Row>
          <Row>
            <Col size={[4, 4, 6, 12]} className="patron-col">
              <TextBlock aosOffset={100} aosDelay={100}>
                <div className="img" data-aos="slide-up" style={{ backgroundImage: `url('${MiastoSpotkanImg}')` }} title="Prezydent Wrocławia" />
                <p className="pt-md-2 pt-0" data-aos="slide-up">
                  <strong>
                    Prezydent Wrocławia <br /> Jacek Sutryk
                  </strong>
                </p>
              </TextBlock>
            </Col>
            <Col size={[4, 4, 6, 12]} className="patron-col">
              <TextBlock aosOffset={200} aosDelay={200}>
                <div className="img" data-aos="slide-up" style={{ backgroundImage: `url('${DolnySlaskImg}')` }} title="Muzeum Narodowe we Wrocławiu" />
                <p className="pt-md-2 pt-0" data-aos="slide-up">
                  <strong>
                    Marszałek Województwa Dolnośląskiego <br /> Cezary Przybylski
                  </strong>
                </p>
              </TextBlock>
            </Col>
          </Row>
        </Grid>
      </Container>
    </ColorSection>
  );
};

export default PatroniSection;
