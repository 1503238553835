import React from "react";
import Col from "../../../components/grid/col";
import Row from "../../../components/grid/row";
import TextBlock from "../../../components/writing-tools/text-block/text-block";
import NewsWrapper from "../news-wrapper";

interface Props {
  className?: string;
}

const NewsPrzenoszenieDoWirtualnej: React.FC<Props> = (props: Props) => {
  const { className } = props;

  return (
    <NewsWrapper className={className}>
      <Row>
        <Col size={[6, 6, 12, 12]}>
          <TextBlock className="pb-md-5 mb-md-5 pb-3 mb-3">
            <p>Zapraszamy do odsłuchania podcastu o tym z czym się wiąże nasza praca. Maciej, jako doświadczony koordynator projektów digitalizacji artefaktów kulturowych, opowiada o wyzwaniach i procesie digitalizacji, a także o przyszłości i możliwościach płynących z tego rodzaju projektów. Miłego słuchania!</p>
          </TextBlock>
        </Col>
        <Col size={[6, 6, 12, 12]}>
          <TextBlock className="pb-md-5 mb-md-5 pb-3 mb-3">
            <p>
              <a href="https://www.radioram.pl/articles/view/42894/FutuRAM-O-przenoszeniu-dziel-sztuki-do-wirtualnej-rzeczywistosci-PODCAST?fbclid=IwAR3d22FmzTv9C6UBkUpCidToUpqWIuW4QMyrsoo7U65W2QVS66GpM_T2FOE" className="red">
                Radio ram
              </a>
            </p>
          </TextBlock>
        </Col>
      </Row>
    </NewsWrapper>
  );
};

export default NewsPrzenoszenieDoWirtualnej;
