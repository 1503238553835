import React from "react";
import ArticlesSwiper from "./articles-swiper/articles-swiper";
import "./articles-section.scss";
import ColorSection from "../../../../components/color-section/color-section";
import Container from "../../../../components/container/container";
import Col from "../../../../components/grid/col";
import Grid from "../../../../components/grid/grid";
import Row from "../../../../components/grid/row";
import TextBlock from "../../../../components/writing-tools/text-block/text-block";
import SwiperNav from "../../../../components/swiper-nav/swiper-nav";

interface Props {
  className?: string;
}

const ArticlesSection: React.FC<Props> = (props: Props) => {
  const { className } = props;

  return (
    <ColorSection className={`articles-section ${className ? className : ""}`} dataAos="zoom-in" color="#FFAF21">
      <Container>
        <div data-aos="slide-up">
          <Grid>
            <Row gap={0}>
              <Col size={[4, 5, 12]} className="content-col">
                <TextBlock>
                  <h2 className="quote-simple pb-5" data-aos="slide-down">
                    <b>Abakanowicz 3d</b>
                  </h2>
                  <p data-aos="slide-up">Projekt &bdquo;Abakanowicz 3d&rdquo; skupia się na digitalizacji 25 unikatowych rzeźb wybitnej, polskiej artystki - Magdaleny Abakanowicz, ze zbiorów Muzeum Narodowego we Wrocławiu oraz upowszechnienie dostępu do eksponatów muzealnych, poprzez przygotowanie atrakcyjnych, wirtualnych materiałów edukacyjnych.</p>
                </TextBlock>
              </Col>
              <Col size={[6, 6, 12]} offset={[2, 1, 0]}>
                <SwiperNav classNamePrev="swiper-button-prev-articles" classNameNext="swiper-button-next-articles" className="slider-nav" />
                <ArticlesSwiper />
              </Col>
            </Row>
          </Grid>
        </div>
      </Container>
    </ColorSection>
  );
};

export default ArticlesSection;
