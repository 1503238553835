import React from "react";
import "./img-text-block.scss";
import TextBlock from "../writing-tools/text-block/text-block";

interface Props {
  alt: string;
  img: string;
  children?: React.ReactNode;
  aosDelay?: number;
  className?: string;
  fullWidth?: boolean;
  childrenInside?: boolean;
}

const ImgtextBlock: React.FC<Props> = (props: Props) => {
  const { img, alt, children, aosDelay = 0, className = "", fullWidth = false, childrenInside = false } = props;

  return (
    <div className={`img-text-block-wrapper ${fullWidth ? "fullWidth" : ""}`} data-aos-delay={aosDelay} data-aos="slide-up">
      <div className={`img-text-block ${childrenInside ? " childrenInside" : ""} ${className}`}>
        <img className="img-text-block__img" src={img} alt={alt} />
        {children ? (
          <TextBlock aos={childrenInside ? "none" : "fade-up"} className="img-text-block__content">
            {children}
          </TextBlock>
        ) : null}
      </div>
    </div>
  );
};

export default ImgtextBlock;
