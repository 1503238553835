import React from "react";
import Footer from "../../sections/footer/footer";
import PrefooterSection from "../../sections/prefooter-section/prefooter-section";
import "./models-page.scss";
import ModelsSection from "./sections/models-section/models-section";

const ModelsPage: React.FC = () => {
  return (
    <div className="models-page">
      <ModelsSection />
      <br />
      <PrefooterSection />
      <Footer />
    </div>
  );
};

export default ModelsPage;
