import React from "react";
import Footer from "../../sections/footer/footer";
import PrefooterSection from "../../sections/prefooter-section/prefooter-section";
import MultimediaSection from "./sections/multimedia-section/multimedia-section";
import "./multimedia-page.scss";

const MultimediaPage: React.FC = () => {
  return (
    <div className="multimedia-page">
      <MultimediaSection />
      <br />
      <PrefooterSection />
      <Footer />
    </div>
  );
};

export default MultimediaPage;
