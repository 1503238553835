import React from "react";
import Col from "../../../components/grid/col";
import Row from "../../../components/grid/row";
import TextBlock from "../../../components/writing-tools/text-block/text-block";
import Prefab from "../../../pages/model-page/sections/model-section/prefab";
import ModelWrapper from "../model-wrapper";
import MutantAudio from "./mutant.ogg";

interface Props {
  className?: string;
}

const ModelMutant: React.FC<Props> = (props: Props) => {
  const { className } = props;

  return (
    <ModelWrapper className={className}>
      <Row>
        <Col size={[12, 12, 12, 12]}>
          <Prefab src="https://sketchfab.com/3d-models/mutanty-9eefc53e541e486c91d9ef11297a8b9c/embed" />
          <TextBlock className="pt-3">
            <h1 className="subtitle pb-4 thin">
              <b>Figury z cyklu Mutanty,</b>&nbsp;1994-1995
            </h1>
          </TextBlock>
        </Col>
      </Row>
      <Row>
        <Col size={[6, 6, 12, 12]} className="pt-3">
          <Row>
            <Col size={[8, 8, 12, 12]}>
              <TextBlock>
                <p>Figury z cyklu „Mutanty” tworzone przez Magdalenę Abakanowicz w latach 1994-95.</p>
                <h2 className="pt-3">
                  <b>Wymiary figur:</b>
                </h2>
                <p>
                  Mutant stojący - 110 x 220 x 50 cm <br />
                  Mutant stojący - 111 x 205 x 53 cm <br />
                  Mutant stojący - 145 x 266 x 50 cm <br />
                  Mutant siedzący - 146 x 244 x 76 <br />
                  Mutant siedzący - 134 x 223 x 70 <br />
                </p>
              </TextBlock>
            </Col>
            <Col size={[4, 4, 12, 12]}>
              <TextBlock>
                <h2 className="pt-3">
                  <b>Materiał:</b>
                </h2>
                <p>tkanina jutowa, żywica, metal.</p>
              </TextBlock>
            </Col>
          </Row>
          <Row>
            <Col size={[12, 12, 12, 12]} className="pt-3">
              <TextBlock>
                <p>Dzieło ze zbiorów Muzeum Narodowego we Wrocławiu prezentowane na wystawie stałej w Pawilonie Czterech Kopuł.</p>
              </TextBlock>
            </Col>
          </Row>
        </Col>
        <Col size={[6, 6, 12, 12]} className="pt-3">
          <TextBlock>
            <p className="title-audio">
              <b>Posłuchaj opisu:</b>
              <audio autoPlay={false} controls controlsList="nofullscreen nodownload noremoteplayback noplaybackrate">
                <source src={MutantAudio} type="audio/ogg" />
                Your browser does not support the audio tag.
              </audio>
            </p>
            <p>
              Pięć figur z cyklu „Mutanty” to rzeźby nawiązujące kształtem do zwierzęcych form. Hybrydowe formy zwierząt, które rozpalają wyobraźnię - mutanty to ulubione rzeźby najmłodszych odbiorców sztuki. Ich różnorodność w sposobie ekspresji, w sposobie siedzenia działa na kreatywność odbiorcy, który może umieścić te dziwne stwory w wyobrażonym przez siebie miejscu. Często pojawiają się takie hasła jak jaskinia, las czy szczyt górski.
              <br /> <br />
              Na metalowe stelaże Magdalena Abakanowicz naklejała nieregularne fragmenty tkaniny jutowej w kolorze jasnobrązowym, nasączone wcześniej klejem i utwardzone żywicą. Faktura mutantów staje się przez to nierówna i chropowata. Każdy z pięciu mutantów jest inny. Artystka inaczej układała ich przednie i tylne łapy, odmienne są również kształty ich pysków. Trzy z nich zostały ukazane w pozycji stojącej, dwa w pozycji siedzącej.
              <br /> <br />
              Patrząc od lewej strony, widzimy mutanta siedzącego o wysokości 145 centymetrów, długości 244 centymetrów oraz szerokości 76 centymetrów. Dwie tylne kończyny przypominają kształtem półkola, płaskimi końcami przylegające do podłoża. Z obu stron mutanta są niemal identyczne. Korpus ułożony jest w kształt przywodzący na myśl siedzącego psa z mocno wyciągniętą do przodu głową. W połowie korpusu umieszczone są dwie przednie, mocno zgeometryzowane, zbliżone do prostopadłościanów nogi.
              <br />
              <br />
              Kolejny mutant to figura stojąca. To najniższy z mutantów, ma 111 centymetrów wysokości, 205 cm długości oraz 53 cm szerokości. Wszystkie cztery kończyny ma w kształcie prostokątów. Tylne łapy płynnie przechodzą w tył korpusu mutanta. Pysk, lekko wysunięty do przodu, zakończony jest obłym kształtem. Wyraźnie widać marszczenia tkaniny jutowej.
              <br />
              <br />
              Środkowy mutant, najbardziej wysunięty do przodu, jest największym ze zbioru. Mutant stojący ma 145 cm wysokości, 266 cm długości oraz 50 cm szerokości. Wygląda jak powiększona wersja wcześniej opisanego mutanta stojącego. Czwarty mutant to ponownie stwór ukazany w pozycji siedzącej. Jednak o 30 cm mniejszy niż pierwszy omówiony mutant siedzący. Jego forma przypomina siedzącego psa, czekającego na wydanie komendy przez właściciela.
              <br />
              <br />
              Piąta postać to równie niski mutant stojący, ma 110 cm wysokości, 220 cm długości oraz 50 cm szerokości. Jest on również najbardziej zaokrąglony w korpusie. W dolnej części, gdzie znajduje się brzuch, jest on bardziej wypukły niż pozostałe mutanty. Również w tym mutancie najbardziej widoczna jest faktura tkaniny.
            </p>
          </TextBlock>
        </Col>
      </Row>
    </ModelWrapper>
  );
};

export default ModelMutant;
