import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ColorSection from "../../components/color-section/color-section";
import Container from "../../components/container/container";
import Col from "../../components/grid/col";
import Grid from "../../components/grid/grid";
import Row from "../../components/grid/row";
import TopLink from "../../components/top-link/top-link";
import TextBlock from "../../components/writing-tools/text-block/text-block";
import NewsNav from "../../pages/aktualnosci-page/blocks/news-nav/news-nav";
import { RoutePath } from "../../route-paths";
import news from "./news";
import "./news.scss";

interface Props {
  className?: string;
  children?: React.ReactNode;
}

const NewsWrapper: React.FC<Props> = (props: Props) => {
  const { className, children } = props;
  let { newsId } = useParams<{ newsId: string }>();

  const [date, setDate] = useState("");
  const [title, setTitle] = useState("");

  useEffect(() => {
    const item = news.find((x) => x.url === newsId);
    setTitle(item?.componentTitle ?? item?.title ?? "");
    setDate(item?.date ?? "");
  }, [newsId]);

  return (
    <ColorSection className={`aktualnosci-section mb-5 ${className ? className : ""}`} dataAos="fade-up" color="white">
      <Container>
        <Grid>
          <Row>
            <Col size={[6, 6, 12, 12]}>
              <TextBlock>
                <TopLink to={RoutePath.HOME} className="more-link mb-4">
                  &lt; Powrót na stronę główną
                </TopLink>
              </TextBlock>
            </Col>
            <Col size={[6, 6, 12, 12]}>
              <NewsNav />
            </Col>
            <Col size={[12, 12, 12, 12]}>
              <TextBlock className="pb-md-5 mb-md-5 pb-3 mb-3">
                <span className="date mb-1" data-aos="fade-up">
                  {date}
                </span>
                <div className="title" data-aos="fade-up">
                  <h2>{title}</h2>
                </div>
              </TextBlock>
            </Col>
          </Row>
          {children}
        </Grid>
      </Container>
    </ColorSection>
  );
};

export default NewsWrapper;
