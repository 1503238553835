import React from "react";
import NewsAImg from "./../../assets/news/newsA.png";
import NewsCImg from "./../../assets/news/newsC.png";
import NewsDImg from "./../../assets/news/newsD.png";
import NewsEImg from "./../../assets/news/newsE.png";
import DigitalFestivalImg from "./digital-festival/digital-festival.jpg";

import NewsDigitalFestival from "./digital-festival/digital-festival";
import NewsOProcesieSkanowania from "./o-procesie-skanowania/o-procesie-skanowania";
import NewsPrzenoszenieDoWirtualnej from "./przenoszenie-do-wirtualnej/przenoszenie-do-wirtualnej";
import NewsSpotkanieZeSztuka from "./spotkanie-ze-sztuka/spotkanie-ze-sztuka";
import NewsTvpWroclaw from "./tvp-wroclaw-o-abakanowicz/tvp-wroclaw-o-abakanowicz";

export interface NewsDto {
  url: string;
  date: string;
  title: string;
  componentTitle?: string;
  component: React.ReactNode;
  previewImg?: string;
}

const news = [
  {
    url: "digital-festival",
    date: "8.10.2021",
    title: "Abakanowicz 3d w ramach Digital Festival! Zapraszamy na webinar na żywo!",
    component: <NewsDigitalFestival />,
    previewImg: DigitalFestivalImg,
  },
  {
    url: "spotkania-ze-sztuka",
    date: "14.09.2021",
    title: "Interaktywne spotkanie ze sztuką Magdaleny Abakanowicz!",
    componentTitle: (
      <>
        Uwaga, Uczniowie i Nauczyciele, <br />
        zapraszamy na interaktywne spotkanie ze sztuką Magdaleny Abakanowicz!
      </>
    ),
    component: <NewsSpotkanieZeSztuka />,
    previewImg: NewsAImg,
  },
  {
    url: "przenoszenie-do-wirtualnej-rzeczywistosci",
    date: "11.09.2021",
    title: "O przenoszeniu sztuki do wirtualnej rzeczywistości",
    component: <NewsPrzenoszenieDoWirtualnej />,
    previewImg: NewsCImg,
  },
  {
    url: "tvp-wroclaw-o-abakanowicz-3d",
    date: "18.08.20221",
    title: "TVP Wrocław o “Abakanowicz 3d”",
    component: <NewsTvpWroclaw />,
    previewImg: NewsDImg,
  },
  {
    url: "o-procesie-skanowania",
    date: "30.08.2021",
    title: "O procesie skanowania",
    component: <NewsOProcesieSkanowania />,
    previewImg: NewsEImg,
  },
] as Array<NewsDto>;

export default news;
