import React from "react";
import "./multimedia-swiper.scss";
import DefaultbgImg from "./../../../../../assets/defaultArticleBg.svg";
import TopLink from "../../../../../components/top-link/top-link";
import TextBlock from "../../../../../components/writing-tools/text-block/text-block";

interface Props {
  alt: string;
  img: string;
  children: React.ReactNode;
  offset?: number;
  to?: string;
  title?: string;
}

const MultimediaTile: React.FC<Props> = (props: Props) => {
  const { img = DefaultbgImg, alt, children, offset, to, title } = props;

  return (
    <div className="multimedia-block-wrapper" style={{ marginTop: offset }} data-aos="slide-up">
      <div className="multimedia-block">
        <div className="multimedia-block__content">
          <img src={img} alt={alt} />
          <TextBlock>{title ? <h3 className="subtitle pt-3">{title}</h3> : null}</TextBlock>
          <p>
            {children} &nbsp;
            {to && children ? (
              <TopLink to={to} className="more-link" label={alt}>
                Więcej&nbsp;&gt;
              </TopLink>
            ) : null}
          </p>
        </div>
      </div>
    </div>
  );
};

export default MultimediaTile;
