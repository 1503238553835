import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import news, { NewsDto } from "../../data/news/news";
import Footer from "../../sections/footer/footer";
import PrefooterSection from "../../sections/prefooter-section/prefooter-section";
import "./aktualnosci-page.scss";
import NoNewsResult from "./sections/aktualnosci-section/no-news-result";
import OtherNewsSection from "./sections/other-news-section/other-news-section";

const AktualnosciPage: React.FC = () => {
  const { newsId }: { newsId: string } = useParams();
  const [newsItem, setNewsItem] = useState<NewsDto | undefined>(undefined);

  useEffect(() => {
    const newsData = news.find((x) => x.url === newsId);
    setNewsItem(newsData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsId]);

  return (
    <div className="aktualnosci-page">
      {newsItem ? newsItem.component : <NoNewsResult />}
      <br />
      <OtherNewsSection />
      <br />
      <PrefooterSection />
      <Footer />
    </div>
  );
};

export default AktualnosciPage;
