import React from "react";
import Container from "../../../../components/container/container";
import Col from "../../../../components/grid/col";
import Grid from "../../../../components/grid/grid";
import Row from "../../../../components/grid/row";
import TopLink from "../../../../components/top-link/top-link";
import TextBlock from "../../../../components/writing-tools/text-block/text-block";
import { RoutePath } from "../../../../route-paths";
import "./check-models-section.scss";
import DreamImg from "./../../../../assets/Mutant_1.png";
import MutantLargeImg from "./../../../../assets/mutant-large.png";
import ColorSection from "../../../../components/color-section/color-section";
import HoverParallax from "../../../../components/hover-parallax/hover-parallax";
import useWindowSize from "../../../../tools/useWindowSize";

interface Props {
  className?: string;
}

const CheckModelsSection: React.FC<Props> = (props: Props) => {
  const { className } = props;
  const windowSize = useWindowSize();

  return (
    <ColorSection className={`check-models-section ${className ? className : ""}`} dataAos="zoom-in" color="white">
      <Container>
        <Grid>
          <Row gap={0} className="articles-section__content">
            {windowSize.isMobile ? null : (
              <Col size={[3, 3, 4, 12]} className="image-col" data-aos="fade-up">
                <HoverParallax>
                  <img data-aos="fade-up" src={MutantLargeImg} alt="Klatka duża grafika" />
                </HoverParallax>
              </Col>
            )}
            <Col size={[7, 7, 6, 10]} offset={[2, 2, 1, 1]} className="content-col">
              <TextBlock>
                <HoverParallax>
                  <h2 className="quote">
                    Najciekawiej jest posługiwać się techniką, której się jeszcze nie zna&nbsp;
                    <b>
                      i budować formy <img src={DreamImg} alt="(Ikona Dream)" />, których się nie zna.
                    </b>
                  </h2>
                  <TopLink to={RoutePath.MODELS} className="more-link">
                    Zobacz modele 3D &gt;
                  </TopLink>
                </HoverParallax>
              </TextBlock>
            </Col>
            {windowSize.isMobile ? (
              <Col size={[3, 3, 4, 12]} className="image-col" data-aos="fade-up">
                <HoverParallax>
                  <img data-aos="fade-up" src={MutantLargeImg} alt="Mutant duża grafika" />
                </HoverParallax>
              </Col>
            ) : null}
          </Row>
        </Grid>
      </Container>
    </ColorSection>
  );
};

export default CheckModelsSection;
