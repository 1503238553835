import React from "react";
import Container from "../../../../components/container/container";
import Col from "../../../../components/grid/col";
import Grid from "../../../../components/grid/grid";
import Row from "../../../../components/grid/row";
import TextBlock from "../../../../components/writing-tools/text-block/text-block";
import "./multimedia-section.scss";
import ColorSection from "../../../../components/color-section/color-section";
import TopLink from "../../../../components/top-link/top-link";
import { RoutePath } from "../../../../route-paths";
import ImgtextPopupBlock from "../../../../components/img-text-popup-block/img-text-popup-block";
import ImmersionAImg from "../../../../assets/multimedia/ImmersionFesitwal/1.jpg";
import ImmersionBImg from "../../../../assets/multimedia/ImmersionFesitwal/2.jpg";
import AbakanDigitalizacjaImg from "../../../../assets/multimedia/AbakanDigitalizacja/1.jpg";
import DreamDigitalizacjaImg from "../../../../assets/multimedia/DreamDigitalizacja/1.jpg";
import ImmersionFesitwal from "./multimedia-popups/ImmersionFesitwal";
import ImmersionFesitwalVideo from "./multimedia-popups/ImmersionFesitwalVideo";
import DreamDigitalizacja from "./multimedia-popups/DreamDigitalizacja";
import AbakanDigitalizacja from "./multimedia-popups/AbakanDigitalizacja";

interface Props {
  className?: string;
}

const MultimediaSection: React.FC<Props> = (props: Props) => {
  const { className } = props;

  return (
    <ColorSection className={`multimedia-section mb-5 ${className ? className : ""}`} dataAos="fade-up" color="white">
      <Container>
        <Grid>
          <Row>
            <Col size={[6, 6, 12, 12]}>
              <TextBlock className="pb-5">
                <div>
                  <TopLink to={RoutePath.HOME} className="more-link mb-4">
                    &lt; Powrót na stronę główną
                  </TopLink>
                </div>
                <p className="title" data-aos="fade-up">
                  <b>Multimedia</b>
                </p>
              </TextBlock>
            </Col>
          </Row>

          <Row className="my-5 py-5">
            <Col size={[6, 6, 12, 12]}>
              <ImgtextPopupBlock img={ImmersionAImg} alt="Immersion Fesitwal" aosDelay={0} fullWidth type="play" popupContent={<ImmersionFesitwalVideo />}>
                <p className="subtitle pt-2">
                  <b>Immersion Fesitwal</b>
                </p>
                <p>Festiwal skupia się wokół prezentacji osiągnięć polskiej branży kreatywnej oraz potencjału szeroko pojętej edukacji wykorzystującej nowe media. Festiwal to szansa na pokazanie jak wielką rolę wirtualna rzeczywistość może odgrywać w różnych dziedzinach sztuki, filmie, nauce i edukacji. Na tegorocznej edycji wzięliśmy udział w panelu: VR i AR w muzeach i instytucjach kultury.</p>
              </ImgtextPopupBlock>
            </Col>
            <Col size={[6, 6, 12, 12]} className="pt-5 mt-5">
              <ImgtextPopupBlock img={ImmersionBImg} alt="Immersion Fesitwal" className="mt-5 pt-5" aosDelay={200} id="ImmersionFesitwal" fullWidth popupContent={<ImmersionFesitwal />}>
                <p className="pt-2">Opowiedzieliśmy o projektach realizowanych przez naszą Fundację m.in Wirtualne Muzea Wrocławia - VR oraz Abakanowicz 3D - AR. Festiwal był dobrą okazją do podzielenia się przemyśleniami, zasadmi pracy nad projektami digitalizacyjnymi oraz do wymiany doświadczeń.</p>
              </ImgtextPopupBlock>
            </Col>
          </Row>

          <Row className="my-5 py-5">
            <Col size={[6, 6, 12, 12]}>
              <ImgtextPopupBlock img={AbakanDigitalizacjaImg} alt="Digitalizacja Abakan" aosDelay={0} fullWidth id="AbakanDigitalizacja" popupContent={<AbakanDigitalizacja />}>
                <p className="subtitle pt-2">
                  <b>Abakan - digitalizacja</b>
                </p>
                <p>Praca Abakan Żółty była najbardziej wymagającym obiektem z całej serii skanowanych prac Magdaleny Abakanowicz w ramach projektu Abakanowicz 3D. Specjalnie dla tego obiektu zostały przygotowane specjalne przęsła naciągowe, które trzymały Abakan w jednej pozycji tak by było możliwe zeskanowanie go z każdej strony. Dodatkowo ze względu na rozmiary obiektu musieliśmy posiłkować się rusztowaniem. Prace nad pozyskaniem zdjęć trwały dwa dni, podczas których pozyskaliśmy ponad 1200 zdjęć obiektu. Zobacz sam, jak wyglądała nasza praca!</p>
              </ImgtextPopupBlock>
            </Col>
            <Col size={[6, 6, 12, 12]} className="pt-5 mt-5">
              <ImgtextPopupBlock img={DreamDigitalizacjaImg} alt="Digitalizacja Dream" className="mt-5 pt-5" aosDelay={200} fullWidth id="DreamDigitalizacja" popupContent={<DreamDigitalizacja />}>
                <p className="subtitle pt-2">
                  <b>Dream - digitalizacja</b>
                </p>
                <p>Prace nad pracą Dream wymagały od nas zastosowania dookólnej fotogrametrii. W związku z gabarytami pracy oraz zastanym oświetleniem proces realizowaliśmy wewnątrz kopuły północnej. Zrealizowaliśmy ponad 500 wysokiej jakości zdjęć, na podstawie kórych następnie przygotowaliśmy trójwymiarowy model - wierną cyfrową kopie obiektu, która następnie została udostępniona w materiałach warsztatowych oraz w formie wydruków 3D.</p>
              </ImgtextPopupBlock>
            </Col>
          </Row>
        </Grid>
      </Container>
    </ColorSection>
  );
};

export default MultimediaSection;
