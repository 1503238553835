import React from "react";
import ColorSection from "../../../../components/color-section/color-section";
import Container from "../../../../components/container/container";
import Col from "../../../../components/grid/col";
import Grid from "../../../../components/grid/grid";
import Row from "../../../../components/grid/row";
import TitleBlock from "../../../../components/title-block/title-block";
import "./multimedia-section.scss";
import MultimediaSwiper from "./multimedia-swiper/multimedia-swiper";

interface Props {
  className?: string;
}

const MultimediaSection: React.FC<Props> = (props: Props) => {
  const { className } = props;

  return (
    <ColorSection className={`multimedia-section ${className ? className : ""}`} dataAos="slide-up" color="#DD494F">
      <Container>
        <div data-aos="slide-up">
          <TitleBlock>
            Multimedia
          </TitleBlock>
        </div>
        <Grid>
          <Row gap={0} className="multimedia-section__content mt-md-5 mt-0">
            <Col size={[12, 12, 12]}>
              <MultimediaSwiper />
            </Col>
          </Row>
        </Grid>
      </Container>
    </ColorSection>
  );
};

export default MultimediaSection;
