import React from "react";
import Footer from "../../sections/footer/footer";
import PrefooterSection from "../../sections/prefooter-section/prefooter-section";
import WarsztatySection from "./sections/warsztaty-section/warsztaty-section";
import "./warsztaty-page.scss";

const WarsztatyPage: React.FC = () => {
  return (
    <div className="warsztaty-page">
      <WarsztatySection />
      <br />
      <PrefooterSection />
      <Footer />
    </div>
  );
};

export default WarsztatyPage;
