import React from "react";
import Container from "../../../../components/container/container";
import Col from "../../../../components/grid/col";
import Grid from "../../../../components/grid/grid";
import Row from "../../../../components/grid/row";
import TextBlock from "../../../../components/writing-tools/text-block/text-block";
import "./sciezki-section.scss";
import ColorSection from "../../../../components/color-section/color-section";
import AbakanowiczProfileImg from "../../../../assets/abakanowicz-profile.jpg";
import TopLink from "../../../../components/top-link/top-link";
import { RoutePath } from "../../../../route-paths";
import FullImgBlock from "../../../../components/fullImg-block/fullImg-block";
import ImgtextBlock from "../../../../components/img-text-block/img-text-block";
import SciezkaTlumImg from "../../../../assets/sciezki/sciezkaA.jpg";
import SciezkaZycieImg from "../../../../assets/sciezki/sciezkaB.jpg";
import MedrcyIcon from "../../../../assets/Medrcy_.png";
import EmbrionyIcon from "../../../../assets/Embriony.png"
import GhostIcon from "../../../../assets/Ghost.png";
import TlumIcon from "../../../../assets/tlum_icon.png"

interface Props {
  className?: string;
}

const SciezkiSection: React.FC<Props> = (props: Props) => {
  const { className } = props;

  return (
    <ColorSection className={`sciezki-section mb-5 ${className ? className : ""}`} dataAos="fade-up" color="white">
      <Container>
        <Grid>
          <Row>
            <Col size={[6, 6, 12, 12]}>
              <TextBlock>
                <div>
                  <TopLink to={RoutePath.HOME} className="more-link mb-4">
                    &lt; Powrót na stronę główną
                  </TopLink>
                </div>
                <h1 className="title" data-aos="fade-up">
                  Ścieżki edukacyjne
                </h1>
              </TextBlock>
            </Col>
          </Row>

          <Row className="my-4 py-4">
            <Col size={[6, 6, 12, 12]}>
              <TextBlock>
                <p>Jednym z najistotniejszych celów, który przyświecał nam podczas realizacji projektu "Abakanowicz 3d" było przybliżenie twórczości artystki jak najszerszemu gronu odbiorców. Dzięki wykorzystaniu nowoczesnych technologii chcieliśmy, aby dorobek kulturowy przekazany przez jedną z najbardziej znanych i rozpoznawalnych polskich artystek, dotarł w ciekawy i angażujący sposób do każdego - od najmłodszych, po najstarszych, a także, co bardzo ważne, do wszystkich tych, którzy ze względu na specjalne potrzeby, nie zawsze są w stanie doświadczać sztuki stacjonarnie - w muzeum.</p>
              </TextBlock>
            </Col>
            <Col size={[6, 6, 12, 12]}>
              <TextBlock>
                <p>Poza wieloma nowoczesnymi sposobami doświadczania sztuki Magdaleny Abakanowicz, takimi jak druki 3d, czy aplikacja AR, przygotowaliśmy również multimedialne ścieżki edukacyjne, wykorzystujące przygotowane przez nas skany trójwymiarowe. Zachęcamy do zapoznania się z nimi poniżej. Dajcie się poprowadzić naszym wirtualnym przewodnikom i dowiedzcie się więcej o życiu oraz twórczości Magdaleny Abakanowicz! Spróbujcie się również odnaleźć w "Tłumie" dzięki naszym wskazówkom. Zapraszamy!</p>
              </TextBlock>
            </Col>
          </Row>

          <Row className="my-4 py-4" align="center">
            <Col size={[5, 6, 12, 12]} offset={[1, 0]}>
              <FullImgBlock img={AbakanowiczProfileImg} alt="Abakanowicz zdjęcie profilowe" />
            </Col>
            <Col size={[5, 6, 12, 12]}>
              <TextBlock>
                <h2 className="title py-4">
                  Magdalena <br /> Abakanowicz
                </h2>
                <p className="middle py-4">Światowej sławy artystka, której prace znajdują się w kolekcjach wielu muzeów. Rzeźbiarka, wykładowczyni, profesorka Akademii Sztuk Pięknych w Poznaniu, autorka nowej formy rzeźby.</p>
              </TextBlock>
            </Col>
          </Row>

          <Row className="my-4 py-4" align="center">
            <Col size={[8, 10, 12, 12]} offset={[2, 1, 0, 0]}>
              <TextBlock>
                <p className="middle py-4 px-xl-5 left"> 
                  Jej prace skłaniają do refleksji <img src={MedrcyIcon} alt="(Ikona Postacie siedzące)" /> zarówno nad zbiorowością <img src={EmbrionyIcon} alt="(Ikona Embriony)" />, jak i indywidualnością <img src={GhostIcon} alt="(Ikona Ghost)" />. Nad tłumem <img src={TlumIcon} alt="(Ikona Tłum)" /> i samotnością w tłumie.
                </p>
              </TextBlock>
            </Col>
          </Row>

          <Row className="my-4 py-4" align="center">
            <Col size={[6, 8, 12, 12]} offset={[3, 2, 0]}>
              <TextBlock>
                <p className="middle py-4 center">Poznaj jej postać i twórczość dzięki przygotowanym przez nas ścieżkom.</p>
              </TextBlock>
            </Col>
          </Row>

          <Row className="my-4 py-4" align="center">
            <Col size={[6, 8, 12, 12]} offset={[3, 2, 0]}>
              <TextBlock>
                <h2 className="title py-4 center">Od której zaczniesz?</h2>
              </TextBlock>
            </Col>
          </Row>

          <Row className="my-4 py-4" gap={0}>
            <Col size={[6, 6, 12, 12]}>
              <a href={"https://www.abakanowicz3d.pl/sciezki-edukacyjne/odnajdz-sie-w-tlumie/"}>
                <ImgtextBlock childrenInside img={SciezkaTlumImg} alt={`Odnajdź się w "Tłumie"`} aosDelay={0} className={"mt-3 pb-md-3 pb-5" + className}>
                  <h3 className="white title pt-2">
                    <b>{`Odnajdź się w "Tłumie"`} &gt;</b>
                  </h3>
                </ImgtextBlock>
              </a>
            </Col>

            <Col size={[6, 6, 12, 12]}>
              <a href={"https://www.abakanowicz3d.pl/sciezki-edukacyjne/zycie-i-tworczosc/"}>
                <ImgtextBlock childrenInside img={SciezkaZycieImg} alt="Życie i twórczość" aosDelay={0} className={"mt-3 pb-md-3 pb-5" + className}>
                  <h3 className="white title pt-2">
                    <b>Życie i twórczość &gt;</b>
                  </h3>
                </ImgtextBlock>
              </a>
            </Col>
          </Row>
        </Grid>
      </Container>
    </ColorSection>
  );
};

export default SciezkiSection;
