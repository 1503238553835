import React, { useEffect } from "react";
import { useFocus } from "../../tools/useFocus";
import "./menu-btn.scss";

interface Props {
  isMenuOpen: Boolean;
}

const MenuBtn: React.FC<Props> = (props: Props) => {
  const [btnRef, setBtnFocus] = useFocus() as any;

  const keyPress = (e: any) => {
    if (e.key === "Escape" || e.key === "esc") {
      setBtnFocus();

      // if (props.isMenuOpen) {
      //   closeMenu
      // } else {
      //   setBtnFocus();
      // }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", keyPress, false);
    return () => {
      document.removeEventListener("keydown", keyPress, false);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <button ref={btnRef} className="menu-btn" tabIndex={3} title={props.isMenuOpen ? "Zamknij menu" : "Otwórz menu"}>
      <div className="top-bar"></div>
      <div className="links">
        <div className="linkBar"></div>
        <div className="linkBar"></div>
        <div className="linkBar"></div>
        <div className="linkBar"></div>
        <div className="linkBar"></div>
      </div>
      <div className="bottom-bar"></div>
    </button>
  );
};

export default MenuBtn;
