import { useState, useEffect } from "react";
import ReduxTheme from "../redux/contrast-store";
import { globalStore } from "./../index";

export default function useTheme() {
  const [theme, setTheme] = useState('');

  useEffect(() => {
    globalStore.Subscribe(ReduxTheme.prefix, localStateChanged);
    localStateChanged(globalStore.GetPartnerState(ReduxTheme.prefix));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const localStateChanged = (localStateChanged: any) => {
    setTheme(localStateChanged.theme);
    if (localStateChanged.theme === 'dark') document.getElementsByTagName('body')[0].style.color = '#fffc01'
    else document.getElementsByTagName('body')[0].style.color = 'black';
  };

  return theme;
}