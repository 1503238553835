import React, { useEffect, useState } from "react";
import "./slide-overlay.scss";
import { useHistory } from "react-router-dom";
import ReduxCallbacks from "../../redux/callback-store";
import { globalStore } from "../../index";
import useTheme from "../../tools/useTheme";
import ReduxBg from "../../redux/bg-store";

interface Props {}

const SlideOverlay: React.FC<Props> = (props: Props) => {
  const [isOverlayActive, setIsOverlayActive] = useState(false);
  const [isOpacityActive, setIsOpacityActive] = useState(false);
  const history = useHistory();
  const theme = useTheme();

  useEffect(() => {
    globalStore.Subscribe(ReduxCallbacks.prefix, localStateChanged);
    localStateChanged(globalStore.GetPartnerState(ReduxCallbacks.prefix));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const localStateChanged = (localStateChanged: any) => {
    if (localStateChanged.slideOverlay.isActive === true) {
      showSlide(localStateChanged.slideOverlay.redirectTo);
    } else if (localStateChanged.slideOverlay.isActive === false) {
      hideSlide();
    }
  };

  const showSlide = (redirectTo: string) => {
    setIsOverlayActive(true);
    setIsOpacityActive(true);
    setTimeout(() => {
      window.scrollTo(0, 0);
      history.push(redirectTo as string);
      if (theme === "dark") ReduxBg.Actions.setBgColor("black", 0);
      else if (theme === "white") ReduxBg.Actions.setBgColor("white", 0);
      ReduxCallbacks.Actions.hideSlideOverlay();
    }, 300);
  };

  const hideSlide = () => {
    setIsOpacityActive(false);
    setTimeout(() => {
      setIsOverlayActive(false);
    }, 300);
  };

  return (
    <div className={`overlayBox${isOverlayActive ? " isActive" : ""} ${theme}`}>
      <div className={`overlayBoxOpacity${isOpacityActive ? " isOpacityActive" : ""}`}></div>
    </div>
  );
};

export default SlideOverlay;
