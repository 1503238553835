import React from "react";
import Container from "../../../../components/container/container";
import Col from "../../../../components/grid/col";
import Grid from "../../../../components/grid/grid";
import Row from "../../../../components/grid/row";
import TextBlock from "../../../../components/writing-tools/text-block/text-block";
import "./team-section.scss";
import ColorSection from "../../../../components/color-section/color-section";
import ImgtextBlock from "../../../../components/img-text-block/img-text-block";
import MaciejImg from "../../../../assets/maciej.png";
import MarcinImg from "../../../../assets/marcin.png";
import RolandImg from "../../../../assets/roland.png";
import useWindowSize from "../../../../tools/useWindowSize";
interface Props {
  className?: string;
}

const TeamSection: React.FC<Props> = (props: Props) => {
  const { className } = props;
  const size = useWindowSize();
  const team = [
    <ImgtextBlock img={MaciejImg} alt="Maciej Mosur" aosDelay={0} className="pt-md-5 pb-md-5 pt-3 pb-3">
      <p className="subtitle pt-3">
        <b>Maciej Mosur</b>
      </p>
      <p>Fundator - doświadczony project manager, specjalista ds. fotogrametrii i digitalizacji. W swoim dorobku ma wiele projektów digitalizacji zbiorów muzealnych m.in. Muzeum Farmacji, Muzeum Politechniki Wrocławskiej, Muzeum Przyrodnicze, czy Muzeum Uniwersytetu Wrocławskiego, a także skanów przestrzeni pod projekty filmowe np. filmu “Hejter”. Odpowiedzialny za przygotowanie modeli 3D i opiekę produkcyjną.</p>
    </ImgtextBlock>,
    <ImgtextBlock img={RolandImg} alt="Roland Lewiński" aosDelay={0} className="pt-5 pb-5">
      <p className="subtitle pt-3">
        <b>Roland Lewiński</b>
      </p>
      <p>Art director / Producent - absolwent stypendium Fulbrighta i były pracownik naukowy Uniwersytetu Oregońskiego; specjalista od spraw komunikacji interkulturowej i dyplomacji publicznej, w tym były pracownik MSZ w sprawach dotyczących promocji Polski w Chinach; post-producent, reżyser animacji i opiekun artystyczny licznych, komercyjnych projektów wizualnych.</p>
    </ImgtextBlock>,
    <ImgtextBlock img={MarcinImg} alt="Marcin Mosur" aosDelay={0} className="pt-mb-5 mt-mb-5 pt-3 mt-3 pb-mb-5 pb-3">
      <p className="subtitle pt-3">
        <b>Marcin Mosur</b>
      </p>
      <p>Prezes Fundacji - VR project manager, doświadczony specjalista w projektowaniu UI oraz rozwiązań UX w aplikacjach VR m.in. dla Małopolskiej Agencji Rozwoju Regionalnego, czy Wydziału Kultury Miasta Wrocławia. Twórca rozwiązań aplikacji AR.</p>
    </ImgtextBlock>,
  ];
  return (
    <ColorSection className={`team-section ${className ? className : ""}`} dataAos="fade-up" color="white">
      <Container>
        <Grid>
          <Row gap={96}>
            <Col size={[12, 12, 12, 12]}>
              <TextBlock>
                <h2 className="title mb-5">Zespół Fundacji</h2>
              </TextBlock>
            </Col>
            <Col size={[6, 6, 12, 12]}>
              {team[0]}
              {size.width <= 600 ? team[2] : null}
              {team[1]}
            </Col>
            {size.width > 600 ? (
              <Col size={[6, 6, 12, 12]} className="pt-mb-5 mt-mb-5 pt-3 mt-3">
                <div className="break">{team[2]}</div>
              </Col>
            ) : null}
          </Row>
        </Grid>
      </Container>
    </ColorSection>
  );
};

export default TeamSection;
