import "./cookie-policy-popup.scss";
import { useEffect, useState } from "react";

import { RoutePath } from "../../route-paths";
import TopLink from "../top-link/top-link";
import ReduxCookie from "../../redux/cookie-store";
import { globalStore } from "../../index";

const CookiePolicyPopup: React.FC = () => {
  const [isCookiePopupHidden, setIsCookiePopupHidden] = useState(false);

  const handleHide = () => {
    ReduxCookie.Actions.hidePolicyPopup();
  };

  useEffect(() => {
    globalStore.Subscribe(ReduxCookie.prefix, localStateChanged);
    localStateChanged(globalStore.GetPartnerState(ReduxCookie.prefix));
  }, []);

  const localStateChanged = (localStateChanged: any) => {
    setIsCookiePopupHidden(localStateChanged.isPopupHidden);
  };

  return (
    <>
      {!isCookiePopupHidden ? (
        <div className="cookies-info-box">
          <div className="left-side">
            <h4>Polityka Prywatności i Cookies</h4>
            <p>Używamy technologi plików cookies, aby analizować ruch na naszej stronie. Przeglądając stronę, akceptujesz warunki użytkowania i&nbsp;politykę prywatności. W&nbsp;każdej chwili możesz zmienić zdanie i&nbsp;wyłączyć pliki cookies w przeglądarce.</p>
          </div>
          <div className="right-side">
            <button className="btn-material-nav" tabIndex={3} onClick={() => handleHide()}>
              Akceptuję
            </button>
            <TopLink to={RoutePath.RULES}>
              <div className="privacy">Szczegóły</div>
            </TopLink>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default CookiePolicyPopup;
