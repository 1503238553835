import React, { useEffect, useRef, useState } from "react";
import Container from "../../../../components/container/container";
import Col from "../../../../components/grid/col";
import Grid from "../../../../components/grid/grid";
import Row from "../../../../components/grid/row";
import useScroll from "../../../../tools/useScroll";
import useTheme from "../../../../tools/useTheme";
import useWindowSize from "../../../../tools/useWindowSize";
import "./hero-section.scss";

interface Props {
  img?: string;
  alt?: string;
  bgImg?: string;
  bgImgDark?: string;
  children?: React.ReactNode;
}

const HeroSection: React.FC<Props> = (props: Props) => {
  const { bgImg, img, bgImgDark, alt } = props;
  const ref = useRef<any>();
  const refff = useRef<any>();
  const scroll = useScroll(ref);
  const [scrollState, setScrollState] = useState(0);
  const [isFinished, setIsFinished] = useState(0);
  const windowSize = useWindowSize();
  const theme = useTheme();

  useEffect(() => {
    if (scroll.refVisibility) {
      const scaledScrollRefVisability = scroll.refVisibility * 1.4;
      const startScrolling = scaledScrollRefVisability >= 0.995 ? 1 : scaledScrollRefVisability <= 0.005 ? 0 : scaledScrollRefVisability;
      setScrollState(startScrolling);
      if (startScrolling === 1) {
        if (isFinished === 0) {
          setIsFinished(window.scrollY);
        }
      } else {
        setIsFinished(0);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scroll]);

  const handleScroll = () => {
    if (refff.current === null) return;
    if (window.scrollY < 1) {
      setScrollState(0);
    }
    requestAnimationFrame(handleScroll);
  };

  useEffect(() => {
    requestAnimationFrame(handleScroll);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="parent" ref={ref} className={`hero-section ${theme}`}>
      <div id="child" ref={refff} className="hero-content" style={{ backgroundImage: `url("${theme === "dark" ? bgImgDark : bgImg}")` }}>
        <Container>
          <Grid>
            <Row>
              <Col size={12}>
                <div className="text-slide" style={{}}>
                  <h1>
                    <img src={img} alt={alt} style={{ transform: `translateX(calc(-${scrollState * 100}% + ${scrollState * 1440 * (windowSize.width < 1620 ? windowSize.width / 1620 : 1)}px))` }} />
                  </h1>
                </div>
              </Col>
            </Row>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default HeroSection;
