import React from "react";
import MultimediaTile from "./multimedia-tile";
import "./multimedia-swiper.scss";

import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { RoutePath } from "../../../../../route-paths";
import useTheme from "../../../../../tools/useTheme";
import useWindowSize from "../../../../../tools/useWindowSize";
import SwiperNav from "../../../../../components/swiper-nav/swiper-nav";
import { a11yOptions } from "../../../../../tools/a11yOptions";

import ImmersionBImg from "./../../../../../assets/multimedia/ImmersionFesitwal/2.jpg";
import AbakanDigitalizacjaImg from "./../../../../../assets/multimedia/AbakanDigitalizacja/1.jpg";
import DreamDigitalizacjaImg from "./../../../../../assets/multimedia/DreamDigitalizacja/1.jpg";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

const MultimediaSwiper: React.FC = () => {
  const size = useWindowSize();
  const theme = useTheme();

  const slides = [
    <MultimediaTile alt="Immersion Fesitwal" img={ImmersionBImg} offset={0} to={RoutePath.MULTIMEDIA} title="Immersion Fesitwal">
      Festiwal skupia się wokół prezentacji osiągnięć polskiej branży kreatywnej oraz potencjału szeroko pojętej edukacji wykorzystującej nowe media. Festiwal to szansa na pokazanie jak wielką rolę wirtualna rzeczywistość może odgrywać w różnych dziedzinach...
    </MultimediaTile>,
    <MultimediaTile alt="Abakan - digitalizacja" img={AbakanDigitalizacjaImg} offset={0} to={RoutePath.MULTIMEDIA} title="Abakan - digitalizacja">
      Praca Abakan Żółty była najbardziej wymagającym obiektem z całej serii skanowanych prac Magdaleny Abakanowicz w ramach projektu Abakanowicz 3D. Specjalnie dla tego obiektu zostały przygotowane specjalne przęsła naciągowe, które trzymały Abakan w jednej pozycji tak by było możliwe...
    </MultimediaTile>,
    <MultimediaTile alt="Dream - digitalizacja" img={DreamDigitalizacjaImg} offset={0} to={RoutePath.MULTIMEDIA} title="Dream - digitalizacja">
      Prace nad pracą Dream wymagały od nas zastosowania dookólnej fotogrametrii. W związku z gabarytami pracy oraz zastanym oświetleniem proces realizowaliśmy wewnątrz kopuły północnej. Zrealizowaliśmy ponad 500 wysokiej jakości zdjęć, na podstawie kórych...
    </MultimediaTile>,
  ];
  return (
    <div className={`multimedia-swiper ${theme}`}>
      <div className="multimedia-wrapper">
        <SwiperNav classNamePrev="swiper-button-prev-multimedia" classNameNext="swiper-button-next-multimedia" className="slider-nav" />
        <div className="subwrapper">
          <div id="livearea-multimedia" aria-live="polite"></div>
          <Swiper
            spaceBetween={size.isMobile ? 32 : 64}
            slidesPerView={size.isMobile ? 1 : 3}
            loop={true}
            navigation={{
              prevEl: ".swiper-button-prev-multimedia",
              nextEl: ".swiper-button-next-multimedia",
            }}
            autoplay={false}
            keyboard={true}
            a11y={a11yOptions}
            onSlideChange={(swiper) => {
              var myslide = swiper.realIndex;
              var swiperLive = document.getElementById("livearea-multimedia");
              if (swiperLive) {
                swiperLive.ariaLabel = `Wyświetlony slajd: ${slides[myslide].props.alt}`;
              }
            }}
          >
            {slides.map((slide: React.ReactNode, index: number) => {
              return <SwiperSlide key={index}>{slide}</SwiperSlide>;
            })}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default MultimediaSwiper;
