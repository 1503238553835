import React from "react";
import Article from "./article";
import "./articles-swiper.scss";
import ArtA from "./../../../../../assets/art1.png";
import ArtB from "./../../../../../assets/art2.png";
import ArtC from "./../../../../../assets/art3.png";
import ArtD from "./../../../../../assets/o_projekcie.jpg";

import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import useWindowSize from "../../../../../tools/useWindowSize";
import { RoutePath } from "../../../../../route-paths";
import useTheme from "../../../../../tools/useTheme";
import { a11yOptions } from "../../../../../tools/a11yOptions";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

const ArticlesSwiper: React.FC = () => {
  const size = useWindowSize();
  const theme = useTheme();

  const slides = [
    <Article
      title={
        <>
          Ścieżki <br />
          edukacyjne
        </>
      }
      alt="Ścieżki edukacyjne"
      bgImg={ArtA}
      offset={300}
      to={RoutePath.SCIEZKI}
    >
      Zanurz się w świecie artystki i dowiedz się więcej o jej twórczości.
    </Article>,
    <Article
      title={
        <>
          Modele <br />
          3D
        </>
      }
      alt="Modele 3D"
      offset={1}
      bgImg={ArtB}
      to={RoutePath.MODELS}
    >
      Zobacz prace Abakanowicz z bliska.
    </Article>,
    <Article title={<>Warsztaty</>} bgImg={ArtC} offset={300} to={RoutePath.WARSZTATY} alt="Warsztaty">
      Dowiedz się jak możesz użyć naszej pracy w nauczaniu.
    </Article>,
    <Article
      title={
        <>
          O <br />
          Projekcie
        </>
      }
      alt="O projekcie"
      bgImg={ArtD}
      offset={99}
      to={RoutePath.ABOUT}
    >
      Poznaj dokładne założenia i plany na przyszłość projektu.
    </Article>,
  ];
  return (
    <>
      <div className={`articles-swiper ${theme}`}>
        <div className="articles-wrapper">
          <div id="livearea-articles" aria-live="polite"></div>
          <Swiper
            spaceBetween={size.isMobile ? 8 : 16}
            slidesPerView={size.isMobile ? 1 : 3}
            // pagination={{
            //   dynamicBullets: true,
            // }}
            navigation={{
              prevEl: ".swiper-button-prev-articles",
              nextEl: ".swiper-button-next-articles",
            }}
            loop={true}
            autoplay={false}
            keyboard={true}
            a11y={a11yOptions}
            onSlideChange={(swiper) => {
              var myslide = swiper.realIndex;
              var swiperLive = document.getElementById("livearea-articles");
              if (swiperLive) {
                swiperLive.ariaLabel = `Wyświetlony slajd: ${slides[myslide].props.alt}`;
              }
            }}
          >
            {slides.map((slide: React.ReactNode, index: number) => {
              return <SwiperSlide key={index}>{slide}</SwiperSlide>;
            })}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default ArticlesSwiper;
