import React from "react";
import { Store } from "redux";
import { globalStore } from "../index";

var initialState = {
  bgColor: { isOpen: false, children: undefined, size: 'normal' }
}

class Actions {
  static setPopupData = (isOpen: boolean, children?: React.ReactNode, size?: 'normal' | 'large') => {
    globalStore.DispatchAction(
      ReduxPopup.prefix,
      {
        type: ReduxPopup.prefix + "setPopupData",
        payload: { isOpen: isOpen, children: children, size: size },
      }
    )
  }
}

class Reducer {
  static Register = (state = initialState, action: any) => {
    if (action.type === ReduxPopup.prefix + "setPopupData")
      return { ...state, popupData: action.payload };
    return state;
  }
}

class Init {
  static Register = () => {
    ReduxPopup.localStore = globalStore.CreateStore(ReduxPopup.prefix, Reducer.Register, []);
    globalStore.RegisterGlobalActions(ReduxPopup.prefix, [
      ReduxPopup.prefix + "setPopupData",
    ]);
  }
}

class ReduxPopup {
  static prefix = "ReduxPopup_";
  static localStore: Store<any, any>;
  Init = Init;
  static Actions = Actions;
}

export default ReduxPopup;