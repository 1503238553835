import React from "react";
import Col from "../../../components/grid/col";
import Row from "../../../components/grid/row";
import TextBlock from "../../../components/writing-tools/text-block/text-block";
import Prefab from "../../../pages/model-page/sections/model-section/prefab";
import ModelWrapper from "../model-wrapper";
import PostacieSiedzaceAudio from "./postacie-siedzace.ogg";

interface Props {
  className?: string;
}

const ModelPostacieSiedzace: React.FC<Props> = (props: Props) => {
  const { className } = props;

  return (
    <ModelWrapper className={className}>
      <Row>
        <Col size={[12, 12, 12, 12]}>
          <Prefab src="https://sketchfab.com/models/2705538a0d4e4fee838d722b66134cb7/embed" />
          <TextBlock className="pt-3">
            <h1 className="subtitle pb-4 thin">
              <b>Postacie&nbsp;siedzące,</b>&nbsp;1974-1984
            </h1>
          </TextBlock>
        </Col>
      </Row>
      <Row>
        <Col size={[6, 6, 12, 12]} className="pt-3">
          <Row>
            <Col size={[8, 8, 12, 12]}>
              <TextBlock>
                <p>Figury z cyklu „Postacie siedzące” stworzone przez Magdalenę Abakanowicz w latach 1974-84.</p>
                <h2 className="pt-3">
                  <b>Wymiary figury:</b>
                </h2>
                <p>
                  wysokość - 110 cm <br />
                  szerokość - 52 cm <br />
                  głębokość - 66 cm
                </p>
                <h2 className="pt-3">
                  <b>Wymiary figury:</b>
                </h2>
                <p>
                  wysokość - 77 cm <br />
                  szerokość - 45 cm <br />
                  głębokość - 23 cm
                </p>
              </TextBlock>
            </Col>
            <Col size={[4, 4, 12, 12]}>
              <TextBlock>
                <h2 className="pt-3">
                  <b>Materiał:</b>
                </h2>
                <p>tkanina jutowa, żywica, metal.</p>
              </TextBlock>
            </Col>
          </Row>
          <Row>
            <Col size={[12, 12, 12, 12]} className="pt-3">
              <TextBlock>
                <p>W kolekcji Muzeum Narodowego we Wrocławiu znajduje się sześć figur.</p>
                <p>Dzieło ze zbiorów Muzeum Narodowego we Wrocławiu prezentowane na wystawie stałej w Pawilonie Czterech Kopuł.</p>
              </TextBlock>
            </Col>
          </Row>
        </Col>
        <Col size={[6, 6, 12, 12]} className="pt-3">
          <TextBlock>
            <p className="title-audio">
              <b>Posłuchaj opisu:</b>
              <audio autoPlay={false} controls controlsList="nofullscreen nodownload noremoteplayback noplaybackrate">
                <source src={PostacieSiedzaceAudio} type="audio/ogg" />
                Your browser does not support the audio tag.
              </audio>
            </p>
            <p>
              Postacie siedzące są doskonałym wstępem do omówienia tego etapu twórczości Magdaleny Abakanowicz, w którym - nie rezygnując z miękkich materiałów - zaczyna ona tworzyć twarde rzeźby. Stworzone w technice odlewu pozwalają na zapoznanie odbiorcy w każdym wieku z problematyką tej techniki oraz z autorską metodą Abakanowicz, która zamaczała fragmenty jutowych worków w kleju i, układając je w odlewach, łącząc fragmenty-łaty, otrzymywała zwielokrotnione, przejmujące, humanoidalne istoty. Cykl „Postaci siedzących” pozwala na dyskusję o wielości i indywidualności dzięki prześledzeniu różnic w pozornie identycznych skorupach.
              <br /> <br />
              Figury z cyklu „Postacie siedzące” autorstwa Magdaleny Abakanowicz zostały wykonane z tkaniny jutowej utwardzonej żywicą. Tytułowe formy usadowione są na stelażach - metalowych rurach stanowiących krawędzie pustego w środku prostopadłościanu. Jedynie uda postaci spoczywają na krótszej części konstrukcji. <br />
              Pośladki wychodzą poza jej płaszczyznę. Każda z figur ukazuje skorupę postaci ludzkiej, pozbawioną przedramion i głów. Choć wszystkie postacie są wykonane z jednego odlewu, w którym Abakanowicz umieszczała fragmenty tkanin, różnią się między sobą fakturą. Na jednych workowe płótno jest przyklejone w gładszy sposób, inne są bardziej pofałdowane z wyraźną teksturą.
            </p>
          </TextBlock>
        </Col>
      </Row>
    </ModelWrapper>
  );
};

export default ModelPostacieSiedzace;
