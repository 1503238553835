import React from "react";
import AImg from "../../../../../assets/multimedia/AbakanDigitalizacja/1.jpg";
import BImg from "../../../../../assets/multimedia/AbakanDigitalizacja/2.jpg";
import CImg from "../../../../../assets/multimedia/AbakanDigitalizacja/3.jpg";
import DImg from "../../../../../assets/multimedia/AbakanDigitalizacja/4.jpg";
import EImg from "../../../../../assets/multimedia/AbakanDigitalizacja/5.jpg";
import FImg from "../../../../../assets/multimedia/AbakanDigitalizacja/6.jpg";
import ImageGallery from 'react-image-gallery';

interface Props {}

const AbakanDigitalizacja: React.FC<Props> = (props: Props) => {
  const images = [
    {
      img: AImg,
      alt: "AbakanDigitalizacja 1",
    },
    {
      img: BImg,
      alt: "AbakanDigitalizacja 2",
    },
    {
      img: CImg,
      alt: "AbakanDigitalizacja 3",
    },
    {
      img: DImg,
      alt: "AbakanDigitalizacja 4",
    },
    {
      img: EImg,
      alt: "AbakanDigitalizacja 5",
    },
    {
      img: FImg,
      alt: "AbakanDigitalizacja 6",
    },
  ];

  return <ImageGallery items={[...images.map((x) => { return { original: x.img } })]} />;
};

export default AbakanDigitalizacja;
