import React from "react";
import ColorSection from "../../components/color-section/color-section";
import Container from "../../components/container/container";
import Col from "../../components/grid/col";
import Grid from "../../components/grid/grid";
import Row from "../../components/grid/row";
import TopLink from "../../components/top-link/top-link";
import TextBlock from "../../components/writing-tools/text-block/text-block";
import { RoutePath } from "../../route-paths";
import "./models.scss";

interface Props {
  className?: string;
  children?: React.ReactNode;
}

const ModelWrapper: React.FC<Props> = (props: Props) => {
  const { className, children } = props;

  return (
    <ColorSection className={`model-section ${className ? className : ""}`} dataAos="fade-up" color="white">
      <Container>
        <Grid>
          <Row>
            <Col size={[12, 12, 12, 12]}>
              <TextBlock className="pb-5">
                <div>
                  <TopLink to={RoutePath.MODELS} className="more-link">
                    &lt; Powrót do modeli 3D
                  </TopLink>
                </div>
              </TextBlock>
            </Col>
          </Row>
          {children}
          <Row>
            <Col size={[12, 12, 12, 12]}>
              <TextBlock className="pb-5 mt-4">
                <div>
                  <TopLink to={RoutePath.MODELS} className="more-link">
                    &lt; Powrót do modeli 3D
                  </TopLink>
                </div>
              </TextBlock>
            </Col>
          </Row>
        </Grid>
      </Container>
    </ColorSection>
  );
};

export default ModelWrapper;
